import * as z from 'zod';
import config from '@/config';

export const FormSchema = z.object({
  name: z.string({
    required_error: 'Name is required',
  }),
  trigger: z.string({
    required_error: 'Trigger is required',
  }),
  schedule: z.string().optional(),
  minute: z.string().optional(),
  hour: z.string().optional(),
  weekday: z.string().optional(),
  day: z.string().optional(),
  custom: z.string().optional(),
  code: z.string().optional(),
});

export const defaultScheduledOptions = {
  schedule: config.SCHEDULE_INTERVAL_OPTIONS[0].value,
  minute: config.MINUTE_INTERVAL_OPTIONS[0].value,
  hour: config.HOUR_INTERVAL_OPTIONS[0].value,
  weekday: config.WEEKDAY_INTERVAL_OPTIONS[0].value,
  day: config.DAY_INTERVAL_OPTIONS[0].value,
  custom: config.DEFAULT_SCHEDULE_INTERVAL,
} as {
  schedule: 'hourly' | 'daily' | 'weekly' | 'monthly' | 'custom';
  minute: string;
  hour: string;
  weekday: string;
  day: string;
  custom: string;
};

export const defaultScheduleFormValues = {
  name: '',
  trigger: '',
  ...defaultScheduledOptions,
};

export type DuplicateFromParams = {
  id: string;
  source: 'workflows' | 'templates';
  name: string;
} & (
  | {
      type: 'triggered';
    }
  | ({
      type: 'scheduled';
    } & typeof defaultScheduledOptions)
);
