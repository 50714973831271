'use client';

import { createContext, useContext, ReactNode, useReducer } from 'react';
import { User } from './types/user';
import { userReducer } from './reducer/user';

type UserContextType = {
  user: User | null;
  setUser: (user: User) => void;
  setActiveProjectId: (projectId: string) => void;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export function useUser(): UserContextType {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}

type UserProviderProps = {
  children: ReactNode;
};

export function UserProvider({ children }: UserProviderProps) {
  const [user, dispatch] = useReducer(userReducer, null);

  const value = {
    user,
    setUser: (user: User) => dispatch({ type: 'SET_USER', payload: user }),
    setActiveProjectId: (projectId: string) =>
      dispatch({ type: 'SET_ACTIVE_PROJECT_ID', payload: projectId }),
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
