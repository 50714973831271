// @ts-strict-ignore
'use client';

import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { useEffect, useState } from 'react';
import OverrideUrl from '../override-url';
import inputStyles from '@/app/components/ui/input-field.styles.module.css';
import { cn } from '@/utils';
import styles from './providers.module.css';

type OAuthProviderProps = {
  onConnect: (alias: string, overrideUrl?: string) => void;
  isLoading: boolean;
  defaultAlias?: string;
  isFetchingAlias?: boolean;
  overrideUrlInfo?: {
    placeholder?: string;
    label?: string;
    required: boolean;
  };
  connection: any;
  buttonText: string;
  onClose?: () => void;
};

export default function OAuthProvider({
  onConnect,
  isLoading,
  defaultAlias,
  isFetchingAlias,
  overrideUrlInfo,
  connection,
  buttonText,
  onClose,
}: OAuthProviderProps) {
  const [connectionAlias, setConnectionAlias] = useState('');
  const [error, setError] = useState('');
  const [overrideUrl, setOverrideUrl] = useState(connection?.overrideUrl || '');

  useEffect(() => {
    if (isFetchingAlias) return;
    if (defaultAlias) setConnectionAlias(defaultAlias);
  }, [isFetchingAlias, defaultAlias]);

  return (
    <div className={styles.container}>
      <div className={styles.inputFrame}>
        <div className={styles.frame}>
          <label htmlFor="slug" className="block font-detail font-medium leading-6 text-slate-900">
            Connection name
          </label>
          <Input
            onChange={(e) => setConnectionAlias(e.target.value)}
            value={isFetchingAlias ? '' : connectionAlias}
            disabled={isFetchingAlias}
            className={cn(inputStyles.inputField, 'font-detail')}
            data-1p-ignore
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onConnect(connectionAlias, overrideUrl);
              }
            }}
          />
        </div>

        {overrideUrlInfo && (
          <OverrideUrl
            defaultValue={overrideUrl}
            onChange={setOverrideUrl}
            onError={setError}
            placeholder={overrideUrlInfo?.placeholder}
            label={overrideUrlInfo?.label}
            required={overrideUrlInfo?.required}
          />
        )}
      </div>

      <div className={styles.buttonsFrame}>
        {onClose && (
          <Button
            onClick={onClose}
            disabled={
              isLoading ||
              !connectionAlias ||
              !!error ||
              (overrideUrlInfo && overrideUrlInfo?.required === true && overrideUrl === '')
            }
            variant="ghost"
            className={cn({
              'opacity-50 cursor-not-allowed': isLoading,
            })}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={() => onConnect(connectionAlias, overrideUrl)}
          disabled={
            isLoading ||
            !connectionAlias ||
            !!error ||
            (overrideUrlInfo && overrideUrlInfo?.required === true && overrideUrl === '')
          }
          className={cn({
            'opacity-50 cursor-not-allowed': isLoading,
          })}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
}
