// @ts-strict-ignore
import { useEffect, useState } from 'react';
import cronstrue from 'cronstrue';
import { Input } from './ui/input';
import * as luxon from 'luxon';
import CronNextExecutions from './cron-next-executions';

function validateCronMinutes(nextExecutions: luxon.DateTime[]): boolean {
  try {
    const nextExecution = nextExecutions[0];
    const nextExecution2 = nextExecutions[1];
    const diffInMinutes = (nextExecution2.valueOf() - nextExecution.valueOf()) / 60000;
    return diffInMinutes >= 5;
  } catch (err) {
    console.error(err);
    return false;
  }
}

export default function ScheduleSelector({
  onChange,
  onBlur,
  onError,
  value,
}: {
  onChange: (value: any) => void;
  onBlur: (value: any) => void;
  onError?: (value: boolean) => void;
  value?: string;
}) {
  const [selectedTime, setSelectedTime] = useState(value);
  const [validatedExpression, setValidatedExpression] = useState('');
  const [validationError, setValidationError] = useState('');
  const [nextExecutions, setNextExecutions] = useState<any[]>([]);

  useEffect(() => {
    setSelectedTime(value);
  }, [value]);

  const validate = (value) => {
    try {
      const expression = cronstrue.toString(value);
      setValidatedExpression(expression);
      setValidationError(null);
      if (onError) onError(false);

      // Validate minutes
      if (nextExecutions.length && !validateCronMinutes(nextExecutions)) {
        setValidationError('Minimum interval is 5 minutes');
        setValidatedExpression('Expression is invalid');
        if (onError) onError(true);
      }
    } catch (error) {
      setValidationError(String(error));
      setValidatedExpression('Expression is invalid');
      if (onError) onError(true);
    }
  };

  useEffect(() => validate(selectedTime), [nextExecutions]);

  const onTimeChange = (event: any) => {
    const value = event.target.value;
    validate(value);
    setSelectedTime(event.target.value);
  };

  useEffect(() => {
    onChange(selectedTime);
  }, [selectedTime]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <Input
          type="text"
          value={selectedTime}
          onChange={onTimeChange}
          onBlur={onBlur}
          className="font-detail"
        />
        <span className="font-detail text-slate-500 max-w-xs">{validatedExpression}</span>
      </div>
      {validationError && <p className=" text-red-400 font-detail">{validationError}</p>}
      <div className="pt-4">
        <CronNextExecutions
          expression={selectedTime}
          onChange={setNextExecutions}
          onError={(error) => {
            onError(true);
            setValidationError('Expression is invalid');
          }}
        />
      </div>
    </div>
  );
}
