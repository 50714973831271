import(/* webpackMode: "eager" */ "/home/ec2-user/strada-ide/rush/common/temp/node_modules/.pnpm/next@14.0.4_63pn4juvsd7pibati2pw4fxddy/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/ec2-user/strada-ide/rush/common/temp/node_modules/.pnpm/next@14.0.4_63pn4juvsd7pibati2pw4fxddy/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/home/ec2-user/strada-ide/rush/frontend/src/app/components/global-dialog.tsx");
import(/* webpackMode: "eager" */ "/home/ec2-user/strada-ide/rush/frontend/src/app/components/initialization.tsx");
import(/* webpackMode: "eager" */ "/home/ec2-user/strada-ide/rush/frontend/src/app/components/query-client.tsx");
import(/* webpackMode: "eager" */ "/home/ec2-user/strada-ide/rush/frontend/src/app/components/stytch-provider.tsx");
import(/* webpackMode: "eager" */ "/home/ec2-user/strada-ide/rush/frontend/src/app/components/ui/toaster.tsx");
import(/* webpackMode: "eager" */ "/home/ec2-user/strada-ide/rush/frontend/src/app/components/user-context-consumer.tsx");
import(/* webpackMode: "eager" */ "/home/ec2-user/strada-ide/rush/frontend/src/app/globals.css");
import(/* webpackMode: "eager" */ "/home/ec2-user/strada-ide/rush/frontend/src/app/ReduxStoreProvider.tsx");
import(/* webpackMode: "eager" */ "/home/ec2-user/strada-ide/rush/frontend/src/context/user.tsx");
import(/* webpackMode: "eager" */ "/home/ec2-user/strada-ide/rush/frontend/src/lib/providers/index.tsx")