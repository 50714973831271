'use client';

import { ReactNode, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useUser } from '@/context/user';
import { usePathname } from 'next/navigation';
import Intercom from '@/utils/intercom';
import request from '@/lib/clients/request';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export default function UserContextConsumer({ children }: { children: ReactNode }) {
  const { setUser } = useUser();
  const pathname = usePathname();

  const { data: user } = useQuery(['userData'], () => request.get('/api/user').then((res) => res.data), {
    enabled: !/^\/auth\/.*/.test(pathname),
    onSuccess(user) {
      setUser(user);

      // Expose user data to GTM
      if (user?.intercomIdentityHash) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          userHash: user.intercomIdentityHash,
          userId: user.id,
          userEmail: user.email,
        });
      }
    },
  });

  useEffect(() => Intercom.boot(), []);

  useEffect(() => {
    if (user) {
      Intercom.update(user);
    }
  }, [user]);

  useEffect(() => {
    if (['/ide'].includes(pathname)) {
      Intercom.hideLauncher();
    }
  }, [pathname]);

  return <>{children}</>;
}
