// @ts-strict-ignore
/* eslint-disable react-hooks/rules-of-hooks */
'use client';

import { setSelectedWorkflowFunctionId } from '@/app/ide/sliceIde';
import { setInitialSnippet } from '@/app/workflows/sliceWorkflow';
import { updateWorkflowFunctions } from '@/app/ide/sliceIde';
import { openNewConnectionDialog, setIsNewWorkflow } from '@/app/workflows/sliceWorkflowsPage';
import {
  ConnectionWithApp,
  WorkflowFunctionWithAppConnection,
  WorkflowFunctionWithConnection,
} from '@/context/types/workflows-page';
import request from '@/lib/clients/request';
import { useAppDispatch, useAppSelector } from '@/lib/redux/hooks';
import { cn } from '@/utils';
import {
  newActionHandler,
  newWorkflowFunctionFromApp,
  newWorkflowFunctionFromConnection,
  replaceAppForCurrentWorkflowFunction,
  replaceConnectionForCurrentWorkflowFunction,
} from '@/utils/actions';
import GTM from '@/utils/gtm';
import { ActionConfig, App, WorkflowFunction } from '@strada/db';
import { StradaDemoApp } from '@strada/types';
import { useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { CheckIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AppLogo from '../logos/app-logo';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../ui/dialog';
import { Input } from '../ui/input';
import { ScrollArea } from '../ui/scroll-area';
import { Skeleton } from '../ui/skeleton';
import styles from './add-or-choose-connection.module.css';

type AddOrChooseConnectionDialogProps = {
  isActive: boolean;
  onClose: () => void;
};

// Requires to be wrapped in a Dialog component
export default function AddOrChooseConnectionDialog({ isActive, onClose }: AddOrChooseConnectionDialogProps) {
  const dispatch = useAppDispatch();
  const workflowsPageState = useAppSelector((state) => state.workflowsPage);
  const workflowFunctionsObject = useAppSelector((state) => state.ide.workflowFunctions);
  const changeConnectionInitiated = useAppSelector((state) => state.workflowsPage.changeConnectionInitiated);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredConnections, setFilteredConnections] = useState<ConnectionWithApp[]>([]);
  const [filteredApps, setFilteredApps] = useState<App[]>([]);
  const idePageLoading = useAppSelector((state) => state.ide._loadingInitalIdePage);

  const workflowFunctions = Object.values(workflowFunctionsObject);
  const selectedWorkflowFunctionId = useAppSelector((state) => state.ide.selectedWorkflowFunctionId);
  const selectedWorkflowFunction = workflowFunctions.find(
    (wf) => wf.id === selectedWorkflowFunctionId
  ) as WorkflowFunctionWithConnection;

  const { data: activeConnections, isLoading: isConnectionsLoading } = useQuery({
    queryKey: ['active-connections'],
    queryFn: () => request.get('/api/connections?active=true').then((res) => res.data),
    enabled: isActive,
  });

  const { data: apps, isLoading: isAppsLoading } = useQuery<App[]>({
    queryKey: ['apps'],
    queryFn: () =>
      request
        .get('/api/apps')
        .then((res) => res.data)
        // Filter out the Strada Demo App
        .then((data) => data.filter((app) => app.slug !== StradaDemoApp))
        .catch(console.error),
    enabled: isActive,
  });

  const getActionConfigList = async (slug): Promise<ActionConfig[]> => {
    let actionConfig = null;
    try {
      actionConfig = await request.get(`/api/actions/config/${slug}`).then((res) => res.data);

      // if insert snippet exists, and it's a new workflow, set the initial snippet
      if (actionConfig[0]?.insertSnippet && workflowsPageState.isNewWorkflow) {
        dispatch(setInitialSnippet(actionConfig[0]?.insertSnippet));
      }
    } catch (error) {
      console.error(error);
    }
    return actionConfig;
  };

  const onSelectConnection = useCallback(
    async (connection: ConnectionWithApp) => {
      // This is also error prone, as it's not guaranteed that the connection has an app
      const actionConfigList = await getActionConfigList(connection?.app?.slug);
      const workflowFunctionsArray = Object.values(workflowFunctionsObject);

      let workflowFunction = null;

      if (workflowsPageState.connectWithApp) {
        // Replace the connection for the selected workflow function
        workflowFunction = replaceConnectionForCurrentWorkflowFunction(
          connection,
          workflowsPageState.connectWithApp
        );

        // Replace the selected workflow function in the workflow functions array
        const index = workflowFunctionsArray.findIndex(
          (wf) => wf.id === workflowsPageState.connectWithApp.id
        );
        workflowFunctionsArray[index] = workflowFunction;

        dispatch(updateWorkflowFunctions(workflowFunctionsArray));
      } else {
        // Create a new workflow function from the connection
        workflowFunction = newWorkflowFunctionFromConnection(connection, actionConfigList[0]);
        const { updatedWorkflowFunctionsArray, newAction } = newActionHandler({
          newAction: workflowFunction,
          workflowFunctionsArray,
          actionConfig: actionConfigList[0],
        });

        // Update the workflow functions
        dispatch(updateWorkflowFunctions([...updatedWorkflowFunctionsArray, newAction]));
        // Update the selected workflow function
        dispatch(setSelectedWorkflowFunctionId(newAction.id));
      }

      // Reset isNewWorkflow state
      dispatch(setIsNewWorkflow(false));

      // Push to data layer
      GTM.pushObjectToDataLayer({
        event: 'add-action',
        description: 'User added an active connection from the connections dialog.',
        active: true,
        app: connection.app.slug,
      });

      onClose();
    },
    [workflowFunctionsObject]
  );

  const onSelectChangeConnection = useCallback(
    async (connection: ConnectionWithApp) => {
      const actionConfigList = await getActionConfigList(connection?.app?.slug);
      const workflowFunctionsArray = Object.values(workflowFunctionsObject);

      // Get the workflow function from the selected connection
      const index = workflowFunctionsArray.findIndex((wf) => wf.id === changeConnectionInitiated.id);

      const currentWorkflowFunction = workflowFunctionsArray[index] as WorkflowFunctionWithAppConnection;

      let newWorkflowFunction = null;
      const newConnection = connection;

      newWorkflowFunction = replaceConnectionForCurrentWorkflowFunction(
        newConnection,
        currentWorkflowFunction,
        actionConfigList[0]
      );

      // Replace the selected workflow function in the workflow functions array
      workflowFunctionsArray[index] = newWorkflowFunction;

      dispatch(updateWorkflowFunctions(workflowFunctionsArray));
      dispatch(setSelectedWorkflowFunctionId(newWorkflowFunction.id));

      // Reset isNewWorkflow state
      dispatch(setIsNewWorkflow(false));

      // Push to data layer
      GTM.pushObjectToDataLayer({
        event: 'add-action',
        description: 'User added an active connection from the connections dialog.',
        active: true,
        app: connection.app.slug,
      });

      onClose();
    },
    [workflowFunctionsObject, changeConnectionInitiated]
  );

  const onSelectApp = useCallback(
    async (app: App) => {
      const actionConfigList = await getActionConfigList(app.slug);
      const workflowFunctionsArray = Object.values(workflowFunctionsObject);

      if (workflowsPageState.connectWithApp) {
        // Open the new connection dialog with the selected workflow function
        dispatch(openNewConnectionDialog(workflowsPageState.connectWithApp));
      } else if (changeConnectionInitiated) {
        // Get the workflow function from the selected connection
        const index = workflowFunctionsArray.findIndex((wf) => wf.id === changeConnectionInitiated.id);

        const workflowFunction = replaceAppForCurrentWorkflowFunction(
          app,
          workflowFunctionsArray[index] as WorkflowFunctionWithAppConnection,
          workflowFunctionsArray,
          actionConfigList[0]
        );

        // @ts-ignore
        workflowFunctionsArray[index] = workflowFunction as WorkflowFunction;
        dispatch(updateWorkflowFunctions(workflowFunctionsArray));

        dispatch(openNewConnectionDialog(workflowFunction));
      } else {
        const workflowFunction = newWorkflowFunctionFromApp(app, actionConfigList[0]);
        const { updatedWorkflowFunctionsArray, newAction } = newActionHandler({
          newAction: workflowFunction as WorkflowFunctionWithAppConnection,
          workflowFunctionsArray,
          actionConfig: actionConfigList[0],
        });
        // Update the workflow functions
        dispatch(updateWorkflowFunctions([...updatedWorkflowFunctionsArray, newAction]));
        // Update the selected workflow function
        dispatch(setSelectedWorkflowFunctionId(newAction.id));
        dispatch(openNewConnectionDialog(newAction));
      }

      // Push to data layer
      GTM.pushObjectToDataLayer({
        event: 'add-action',
        description: 'User added an app from the connections dialog.',
        active: false,
        app: app.slug,
      });
    },
    [workflowFunctionsObject, workflowsPageState.connectWithApp]
  );

  const debouncedSearchTerm = useMemo(
    () => debounce(setSearchTerm, 150),
    [] // will only be created once initially
  );

  useEffect(() => {
    if (!activeConnections || !activeConnections.length) return;

    // Use searchTerm for filtering the connections
    setFilteredConnections(
      activeConnections.filter(
        (connection) =>
          connection.app?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          connection.alias?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    // Use connectWithApp slug to filter out the current app
    if (workflowsPageState.connectWithApp) {
      setFilteredConnections(
        activeConnections
          .filter((connection) => connection.app.slug === workflowsPageState.connectWithApp?.app.slug)
          .filter(
            (connection) =>
              connection.app?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              connection.alias?.toLowerCase().includes(searchTerm.toLowerCase())
          )
      );
    }
  }, [activeConnections, searchTerm, workflowsPageState.connectWithApp]);

  useEffect(() => {
    if (!apps || !apps.length) return;

    // Use searchTerm for filtering the apps
    setFilteredApps(apps?.filter((app) => app.name.toLowerCase().includes(searchTerm.toLowerCase())));

    // Use connectWithApp slug to filter out the current app
    if (workflowsPageState.connectWithApp) {
      setFilteredApps(
        apps
          .filter((app) => app.slug === workflowsPageState.connectWithApp?.app.slug)
          .filter((app) => app.name.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
  }, [apps, searchTerm, workflowsPageState.connectWithApp]);

  let title = 'Add connection';
  let description = 'Select a new or active connection for your workflow.';
  if (workflowsPageState.isNewWorkflow) {
    description = 'Select the first connection for your workflow.';
  } else if (workflowsPageState.connectWithApp) {
    title = 'Choose connection';
    description = 'Select a new or existing connection for your workflow.';
  } else if (changeConnectionInitiated) {
    title = 'Change connection';
    description = 'Select the connection you want to use.';
  }

  return (
    <>
      <DialogContent
        overrideClose={
          workflowsPageState.isNewWorkflow && <span className="font-detail text-slate-900">Skip</span>
        }
        className={cn(styles.container)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onInteractOutside={(event) => event.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>

        <div className="min-h-[316px] w-full">
          {/* Searchbox */}
          <div className={cn(styles.command)}>
            <Input
              className={cn(styles.searchbox)}
              tabIndex={1}
              placeholder="Search..."
              onChange={(e) => {
                e.persist(); // to access event asynchronously
                debouncedSearchTerm(e.target.value);
              }}
            />

            {/* List of connections */}
            <div className={styles.frame}>
              <ScrollArea
                onKeyDown={(event) => {
                  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                    event.preventDefault();
                  }
                }}
                className="flex flex-col w-full"
                scrollHideDelay={2000}
              >
                {(isConnectionsLoading || isAppsLoading || idePageLoading) && <LoadingPlaceholder />}
                {!isConnectionsLoading && filteredConnections?.length > 0 && (
                  <h3 className={cn('text-xs font-bold sticky top-0 bg-white font-detail')}>Connected</h3>
                )}
                {filteredConnections?.length > 0 && (
                  <ConnectionsList
                    isLoading={isConnectionsLoading}
                    items={filteredConnections}
                    onSelectConnection={(connection) => {
                      if (changeConnectionInitiated) onSelectChangeConnection(connection);
                      else onSelectConnection(connection);
                    }}
                    selectedWorkflowFunction={selectedWorkflowFunction}
                  />
                )}

                {!isConnectionsLoading && filteredApps?.length > 0 && (
                  <h3 className={cn('text-xs font-bold sticky top-0 bg-white font-detail')}>Add new</h3>
                )}
                {filteredApps?.length > 0 && <AppsList items={filteredApps} onSelectApp={onSelectApp} />}

                {!isConnectionsLoading &&
                  searchTerm?.length > 0 &&
                  filteredConnections?.length === 0 &&
                  filteredApps?.length === 0 && (
                    <NoResultsPlaceholder withRequestLink={!workflowsPageState.connectWithApp} />
                  )}
              </ScrollArea>
            </div>
          </div>
        </div>
      </DialogContent>
    </>
  );
}

function ConnectionsList({
  isLoading,
  items,
  onSelectConnection,
  selectedWorkflowFunction,
}: {
  isLoading: boolean;
  items: ConnectionWithApp[];
  onSelectConnection: (connection: ConnectionWithApp) => void;
  selectedWorkflowFunction: WorkflowFunctionWithConnection;
}) {
  const changeConnectionInitiated = useAppSelector((state) => state.workflowsPage.changeConnectionInitiated);
  const connectWithApp = useAppSelector((state) => state.workflowsPage.connectWithApp);

  return (
    <div className={styles.innerFrame}>
      {!isLoading &&
        items?.length > 0 &&
        items.map((connection: ConnectionWithApp, index) => (
          <div
            key={connection.id}
            onClick={() => onSelectConnection(connection)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                onSelectConnection(connection);
              }
            }}
            className="p-[6px] space-x-2 w-full flex items-center cursor-pointer hover:bg-slate-50 focus:outline-none focus:bg-slate-50 rounded-[6px]"
            tabIndex={index + 1} // make this div focusable
          >
            <AppLogo size={16} className="w-6 h-6" slug={connection.app.slug} />
            <span className="font-detail font-medium leading-5">{connection.app.name}</span>
            <span className={cn('font-detail font-normal leading-5 text-zinc-400', styles.alias)}>
              {connection.alias}
            </span>

            {selectedWorkflowFunction && changeConnectionInitiated && !connectWithApp && (
              <CheckIcon
                className={cn(
                  'ml-auto h-4 w-4',
                  connection.id === selectedWorkflowFunction?.connection.id ? 'opacity-100' : 'opacity-0'
                )}
              />
            )}
          </div>
        ))}
    </div>
  );
}

function AppsList({ items, onSelectApp }: { items: App[]; onSelectApp: (app: App) => void }) {
  return (
    <div className={cn(styles.innerFrame, styles.appList)}>
      {items?.length > 0 &&
        items.map((app, index) => (
          <div
            key={app.id}
            onClick={() => onSelectApp(app)}
            onKeyDown={(event) => {
              if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                onSelectApp(app);
              }
            }}
            className="p-[6px] space-x-2 w-full flex items-center cursor-pointer hover:bg-slate-50 focus:outline-none focus:bg-slate-50 rounded-[6px]"
            tabIndex={index + 1} // make this div focusable
          >
            <AppLogo size={16} className="w-6 h-6" slug={app.slug} />
            <span className="font-detail font-medium leading-5">{app.name}</span>
          </div>
        ))}
    </div>
  );
}

function LoadingPlaceholder() {
  return (
    <div className="w-full">
      <Skeleton className="w-[180px] h-[18px] rounded-lg mt-4" />
      <Skeleton className="w-full h-[18px] rounded-lg mt-4" />
      <Skeleton className="w-full h-[18px] rounded-lg mt-4" />
      <Skeleton className="w-full h-[18px] rounded-lg mt-4" />
      <Skeleton className="w-full h-[18px] rounded-lg mt-4" />
      <Skeleton className="w-full h-[18px] rounded-lg mt-4" />
      <Skeleton className="w-full h-[18px] rounded-lg mt-4" />
      <Skeleton className="w-full h-[18px] rounded-lg mt-4" />
    </div>
  );
}

function NoResultsPlaceholder({ withRequestLink = true }) {
  return (
    <div className="flex flex-col font-normal w-full h-full font-detail text-black mt-1 justify-start">
      <p>No results found.</p>
      {withRequestLink && (
        <p className="mt-4">
          Connect a REST API with Custom HTTP or{' '}
          <a id="ide-request-new-integration-link" className="underline" href="mailto:support@getstrada.com">
            request a new integration
          </a>
          .
        </p>
      )}
    </div>
  );
}
