import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/app/components/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/app/components/ui/select';
import config from '@/config';
import { FormProps } from 'react-hook-form';

export default function ScheduleSelectorDay({ form }: { form: FormProps<any> }) {
  return (
    <FormField
      control={form.control}
      name="day"
      render={({ field }) => (
        <FormItem className="grid grid-cols-12 space-y-0 space-x-4">
          <div className="col-span-2 flex items-center justify-end">
            <FormLabel>Day</FormLabel>
          </div>
          <div className="col-span-10 items-center justify-start">
            <Select
              onValueChange={field.onChange}
              defaultValue={field.value || config.DAY_INTERVAL_OPTIONS[0].value}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select the day" />
                </SelectTrigger>
              </FormControl>
              <SelectContent className="max-h-40">
                {config.DAY_INTERVAL_OPTIONS.map((interval) => (
                  <SelectItem key={interval.value} value={interval.value}>
                    {interval.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <FormMessage className="col-span-10 pt-2" />
        </FormItem>
      )}
    />
  );
}
