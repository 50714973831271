import { User } from '../types/user';

export function userReducer(state: User | null, action: any): User | null {
  switch (action.type) {
    case 'SET_USER':
      return action.payload;
    case 'SET_ACTIVE_PROJECT_ID':
      if (state) {
        return { ...state, activeProjectId: action.payload };
      }
      return state;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}
