import * as React from 'react';

import { cn } from '@/utils';
import styles from './input-field.styles.module.css';
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        'flex w-full transition-color',
        'file:border-0 file:bg-transparent file:font-detail file:font-medium placeholder:text-muted-foreground placeholder:font-detail focus-visible:outline-none placeholder:text-slate-400',
        `focus-visible:ring-0`,
        'disabled:cursor-not-allowed disabled:opacity-50',
        styles.inputField,
        className
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = 'Input';

export { Input };
