// @ts-strict-ignore
type AdditionalOptions = {
  allowSpaces?: boolean;
  charLimit?: number;
};

const defaultOptions: AdditionalOptions = {
  allowSpaces: false,
  charLimit: 3,
};

const validateName = (newName: string, options: AdditionalOptions = defaultOptions) => {
  const trimmed = newName.trim();
  if (!trimmed) {
    return { isValid: false, errorHint: 'Name cannot be empty.' };
  }
  if (trimmed.length < options.charLimit) {
    return { isValid: false, errorHint: `Name must be at least ${options.charLimit} characters.` };
  }

  if (!options.allowSpaces && /\s/.test(trimmed)) {
    return { isValid: false, errorHint: 'Name cannot contain spaces.' };
  }

  return { isValid: true, errorHint: '' };
};

export default validateName;
