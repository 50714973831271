import { useMutation, useQueryClient } from '@tanstack/react-query';
import request from '@/lib/clients/request';
import { connectionsKeys } from './keys';
import {
  PostConnectionsDatabaseRequest,
  PostConnectionsDatabaseResponse,
} from '@/app/api/connections/database/types';
import { AxiosResponse } from 'axios';

export const useCreateDatabaseConnection = (
  onSuccess: (connection: PostConnectionsDatabaseResponse) => void,
  onError: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation({
    // @ts-ignore
    onSuccess: (response: AxiosResponse<PostConnectionsDatabaseResponse>) => {
      const connection = response.data;
      queryClient.invalidateQueries({ queryKey: connectionsKeys.connectionAlias(connection.app.slug) });
      onSuccess(connection);
    },
    onError: () => {
      onError();
    },
    mutationFn: (body: PostConnectionsDatabaseRequest) =>
      request.post<PostConnectionsDatabaseResponse, PostConnectionsDatabaseResponse>(
        `/api/connections/database`,
        body
      ),
  });
};
