declare global {
  interface Window {
    dataLayer: any[];
  }
}

type GTMOptions = {
  allowDuplicates?: boolean;
};

// Create a class to handle Google Tag Manager
export default class GTM {
  // initialize Google Tag Manager
  static initialize() {
    window.dataLayer = window.dataLayer || [];
  }

  /**
   * Use this method to push a variable to the data layer avoiding duplicates
   */
  static pushOrUpdateDataToDataLayer(key: string, value: any) {
    this.initialize();

    // Check if the data layer already contains the key
    const dataLayerIndex = window.dataLayer.findIndex((item) => item[key] !== undefined);

    if (dataLayerIndex !== -1) {
      // If the key exists, update the value
      window.dataLayer[dataLayerIndex][key] = value;
    } else {
      // If the key doesn't exist, push the data
      window.dataLayer.push({ [key]: value });
    }
  }

  /**
   *  Use this method to push an event or object to the data layer avoiding duplicates
   */
  static pushObjectToDataLayer(obj: any, options: GTMOptions = { allowDuplicates: false }) {
    this.initialize();

    // Check if the object is empty
    if (Object.keys(obj).length === 0 || obj.constructor !== Object || obj === null) {
      console.error('Object is empty');
      return;
    }

    window.dataLayer.push(obj);
    if (process.env.NODE_ENV === 'development') {
      console.groupCollapsed(
        `%cGTM | ${Object.keys(obj)[0]}: ${obj[Object.keys(obj)[0]]}`,
        'color: #492E87; padding: 0px 2px;border-radius: 2px;'
      );
      console.log('Object:', obj);
      console.log('Result', window.dataLayer);
      console.groupEnd();
    }
  }
}
