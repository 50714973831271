// List of configuration variables

const config = {
  STRADA_ENG_TEAM_PROJECT_ID: 'proj_07b9074d-1e0c-4636-afbf-f05d03b5361a',

  // List of supported openai models
  supportedModels: ['gpt-4', 'gpt-3.5-turbo', 'gpt-3.5-turbo-16k'],
  webhookUrl: `${process.env.NEXT_PUBLIC_STRADA_IDE_SERVER || 'http://localhost:3003'}/webhook/`,
  TOAST_DURATION: 6000,
  HOST: process.env.NEXT_PUBLIC_HOST || 'http://localhost:3000',
  ENV: process.env.NEXT_PUBLIC_APP_ENV || 'development',
  TRIGGERED_WORKFLOW_PREFIX: 'trgw_',
  SCHEDULED_WORKFLOW_PREFIX: 'schw_',
  HOME_URL: '/workflows',
  FILESYSTEM_ROOT_DIRECTORY: '/',
  DEFAULT_SCHEDULE_INTERVAL: '*/5 * * * *', // every 5 minutes,
  DEBOUNCE_SAVE_DELAY_MS: 1200,
  DATETIME_FORMAT: "MMM dd', 'yyyy' 'h':'mm' 'a",
  TIMESTAMP_FORMAT: "yyyy-MM-dd' 'hh':'mm':'ss'.'SSS",
  DATETIME_LOCALE: 'en-US',
  CUSTOM_FF_HEADER: 'X-STRADA-FF',
  SESSION_COOKIE_JWT: 'stytch_session_jwt',
  SESSION_COOKIE: 'stytch_session',
  SESSION_TYPE_COOKIE: 'stytch_client_type',
  SESSION_CSRF_COOKIE: 'csrftoken',
  DATADOG_TOKEN: 'pub4444bed3f2c62292c8a100ae6a59570b', // TODO: Move to per-environment config
  ENABLED_SCRIPT_DOMAINS: [
    'https://app.getstrada.com', // Allow scripts from the same origin
    'https://staging.app.getstrada.com', // Allow scripts from the same origin
    'js-agent.newrelic.com',
    'https://app.posthog.com',
    'https://www.googletagmanager.com',
    'https://widget.intercom.io',
    'https://js.intercomcdn.com',
    'https://api.dataunlocker.com',
    'https://cdn.jsdelivr.net',
    'https://6kuumb1e.staging.app.getstrada.com',
  ],
  SCHEDULE_INTERVAL_OPTIONS: [
    { label: 'Every Hour', value: 'hourly' },
    { label: 'Every Day', value: 'daily' },
    { label: 'Every Week', value: 'weekly' },
    { label: 'Every Month', value: 'monthly' },
    { label: 'Custom (Cron)', value: 'custom' },
  ],
  MINUTE_INTERVAL_OPTIONS: [
    { label: '0 past the hour', value: '0' },
    { label: '5 past the hour', value: '5' },
    { label: '10 past the hour', value: '10' },
    { label: '15 past the hour', value: '15' },
    { label: '20 past the hour', value: '20' },
    { label: '25 past the hour', value: '25' },
    { label: '30 past the hour', value: '30' },
    { label: '35 past the hour', value: '35' },
    { label: '40 past the hour', value: '40' },
    { label: '45 past the hour', value: '45' },
    { label: '50 past the hour', value: '50' },
    { label: '55 past the hour', value: '55' },
  ],
  HOUR_INTERVAL_OPTIONS: [
    { label: '12:00 AM', value: '0' },
    { label: '1:00 AM', value: '1' },
    { label: '2:00 AM', value: '2' },
    { label: '3:00 AM', value: '3' },
    { label: '4:00 AM', value: '4' },
    { label: '5:00 AM', value: '5' },
    { label: '6:00 AM', value: '6' },
    { label: '7:00 AM', value: '7' },
    { label: '8:00 AM', value: '8' },
    { label: '9:00 AM', value: '9' },
    { label: '10:00 AM', value: '10' },
    { label: '11:00 AM', value: '11' },
    { label: '12:00 PM', value: '12' },
    { label: '1:00 PM', value: '13' },
    { label: '2:00 PM', value: '14' },
    { label: '3:00 PM', value: '15' },
    { label: '4:00 PM', value: '16' },
    { label: '5:00 PM', value: '17' },
    { label: '6:00 PM', value: '18' },
    { label: '7:00 PM', value: '19' },
    { label: '8:00 PM', value: '20' },
    { label: '9:00 PM', value: '21' },
    { label: '10:00 PM', value: '22' },
    { label: '11:00 PM', value: '23' },
  ],
  WEEKDAY_INTERVAL_OPTIONS: [
    { label: 'Monday', value: '1' },
    { label: 'Tuesday', value: '2' },
    { label: 'Wednesday', value: '3' },
    { label: 'Thursday', value: '4' },
    { label: 'Friday', value: '5' },
    { label: 'Saturday', value: '6' },
    { label: 'Sunday', value: '7' },
  ],
  DAY_INTERVAL_OPTIONS: [
    { label: '1st', value: '1' },
    { label: '2nd', value: '2' },
    { label: '3rd', value: '3' },
    { label: '4th', value: '4' },
    { label: '5th', value: '5' },
    { label: '6th', value: '6' },
    { label: '7th', value: '7' },
    { label: '8th', value: '8' },
    { label: '9th', value: '9' },
    { label: '10th', value: '10' },
    { label: '11th', value: '11' },
    { label: '12th', value: '12' },
    { label: '13th', value: '13' },
    { label: '14th', value: '14' },
    { label: '15th', value: '15' },
    { label: '16th', value: '16' },
    { label: '17th', value: '17' },
    { label: '18th', value: '18' },
    { label: '19th', value: '19' },
    { label: '20th', value: '20' },
    { label: '21st', value: '21' },
    { label: '22nd', value: '22' },
    { label: '23rd', value: '23' },
    { label: '24th', value: '24' },
    { label: '25th', value: '25' },
    { label: '26th', value: '26' },
    { label: '27th', value: '27' },
    { label: '28th', value: '28' },
    { label: '29th', value: '29' },
    { label: '30th', value: '30' },
    { label: '31st', value: '31' },
  ],
  LOG_TIMESTAMPS_REGEX: /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+Z(.*)/,
} as const;

export default config;
