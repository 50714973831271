import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { cn } from '@/utils';
import inputStyles from '@/app/components/ui/input-field.styles.module.css';
import styles from './providers.module.css';
import { useCallback, useEffect } from 'react';
import { BasicAuthConnectionSchema, BasicAuthFormData } from './types';

type BasicAuthConnectionFormProps = {
  onConnect: (formData: BasicAuthFormData) => void;
  isLoading: boolean;
  defaultAlias?: string;
  isFetchingAlias?: boolean;
  buttonText: string;
  onClose?: () => void;
  usernamePlaceholder?: string;
  passwordPlaceholder?: string;
};

export default function BasicAuthConnectionForm({
  onConnect,
  isLoading,
  defaultAlias,
  isFetchingAlias,
  buttonText,
  onClose,
  usernamePlaceholder,
  passwordPlaceholder,
}: BasicAuthConnectionFormProps) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<BasicAuthFormData>({
    mode: 'all',
    resolver: zodResolver(BasicAuthConnectionSchema),
  });

  const onSubmit = useCallback(
    (data: BasicAuthFormData) => {
      onConnect(data);
    },
    [onConnect]
  );

  useEffect(() => {
    if (!isFetchingAlias && defaultAlias) {
      setValue('connectionName', defaultAlias);
    }
  }, [isFetchingAlias, defaultAlias, setValue]);

  return (
    <form className={`${styles.container} font-detail`} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.inputFrame}>
        {/* Connection Input */}
        <div className={styles.frame}>
          <label htmlFor="connectionName" className="block font-detail font-medium leading-6 text-slate-900">
            Connection name
          </label>
          <div className="w-full">
            <Input
              {...register('connectionName')}
              className={cn(inputStyles.inputField, 'font-detail')}
              disabled={isLoading}
            />
            {errors.connectionName && <p className="text-red-500">{errors.connectionName.message}</p>}
          </div>
        </div>

        {/* Username Input */}
        <div className={styles.frame}>
          <label htmlFor="username" className="block font-detail font-medium leading-6 text-slate-900">
            Username
          </label>
          <div className="w-full">
            <Input
              {...register('username')}
              className={cn(inputStyles.inputField, 'font-detail')}
              placeholder={usernamePlaceholder || 'User Name'}
              disabled={isLoading}
            />
            {errors.username && <p className="text-red-500">{errors.username.message}</p>}
          </div>
        </div>

        {/* Password Input */}
        <div className={styles.frame}>
          <label htmlFor="password" className="block font-detail font-medium leading-6 text-slate-900">
            Password
          </label>
          <div className="w-full">
            <Input
              type="password"
              {...register('password')}
              className={cn(inputStyles.inputField, 'font-detail')}
              placeholder={passwordPlaceholder || 'Password'}
              onFocus={(event) => {
                //@ts-ignore
                event.target.type = 'text';
              }}
              onMouseEnter={(event) => {
                //@ts-ignore
                event.target.type = 'text';
              }}
              onMouseLeave={(event) => {
                //@ts-ignore
                event.target.type = 'password';
              }}
              disabled={isLoading}
            />
            {errors.password && <p className="text-red-500">{errors.password.message}</p>}
          </div>
        </div>
      </div>

      <div className={styles.buttonsFrame}>
        {onClose && (
          <Button
            onClick={onClose}
            disabled={isLoading}
            variant="ghost"
            className={cn({
              'opacity-50 cursor-not-allowed': isLoading,
            })}
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          disabled={isLoading}
          className={cn({
            'opacity-50 cursor-not-allowed': isLoading,
          })}
        >
          {buttonText}
        </Button>
      </div>
    </form>
  );
}
