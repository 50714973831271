// @ts-strict-ignore
'use client';

import { useToast } from '@/app/components/ui/use-toast';
import config from '@/config';
import request from '@/lib/clients/request';
import validateName from '@/utils/validateName';
import Nango from '@nangohq/frontend';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import APIKeyConnection from '../providers/api-key-connection';
import CustomHttpConnection from '../providers/custom-http-connection';
import OAuthProvider from '../providers/oauth-provider';
import OpenAIChatGPT from '../providers/openai-chatgpt';
import DBConnectionForm from '../providers/db-connection';
import { useGenerateConnectionAlias } from './queries/useGenerateConnectionAlias';
import { useCreateDatabaseConnection } from './queries/useCreateDatabaseConnection';
import { useUpdateDatabaseConnection } from './queries/useUpdateDatabaseConnection';
import { BasicAuthFormData, DBConnectionFormData } from '../providers/types';
import BasicAuthConnectionForm from '../providers/basic-auth-connection';
import { queryClient } from '../query-client';
import { connectionsKeys } from '@/app/connections/queries/keys';

const nango = new Nango({
  host: process.env.NEXT_PUBLIC_NANGO_SERVER_URL,
  publicKey: process.env.NEXT_PUBLIC_NANGO_PUBLIC_KEY as string,
});

export default function ConnectionForm({
  app,
  connection,
  onComplete,
  onClose,
}: {
  app: any;
  connection?: any;
  onComplete?: (connection) => void;
  onClose?: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { toast } = useToast();

  // Fetch connection alias only if connection is not passed
  const {
    data: connectionAlias,
    isLoading: isLoadingAlias,
    isFetching: isFetchingAlias,
    isRefetching: isRefetchingAlias,
  } = useGenerateConnectionAlias(app.slug);

  const onSuccessConnection = useCallback(
    (connection: any) => {
      // Invalidate getConnections query
      queryClient.invalidateQueries({ queryKey: connectionsKeys.connections });

      if (!connection.id) {
        setError(connection.error);
        setIsLoading(false);
        return;
      }
      if (connection?.credentialsInfo?.type === 'nango') {
        let params = undefined;
        if (connection.app.slug === 'zendesk' && connection.overrideUrl) {
          const subdomain = connection.overrideUrl.split('https://')[1].split('.zendesk.com')[0];
          params = { subdomain };
        }

        nango
          .auth(connection.app.slug, connection.id, { params })
          .then(async (nangoData) => {
            // Activate connection
            const body = { connectionId: connection.id };

            await request.put('/api/connections', body);

            if (!nangoData) {
              alert('Connection failed.');
              return;
            }

            if (onComplete) onComplete(connection);
            else window.location.href = config.HOST + '/connections';
          })
          .catch((err: { message: string; type: string }) => {
            console.error(err);
            toast({
              duration: config.TOAST_DURATION,
              title: `Connection failed with ${connection.app.name}`,
              description: (
                <>
                  {/* <p>{`Error: ${err.message}`}</p> */}
                  <p>Please verify your credentials and try again.</p>
                </>
              ),
              variant: 'destructive',
            });
            setIsLoading(false);
          });
      } else {
        if (onComplete) onComplete(connection);
        else window.location.href = config.HOST + '/connections';
      }
    },
    [onComplete, toast]
  );

  const createConnection = useMutation({
    mutationFn: (body: any) => {
      const additionalOptions = {};
      setIsLoading(true);
      if (body.url) additionalOptions['url'] = body.url;

      return request
        .post('/api/connections', {
          appSlug: app.slug,
          apiKey: body.apiKey,
          overrideUrl: body.overrideUrl,
          additionalOptions,
          alias: body.alias,
          authentication: body.authentication,
        })
        .then((res) => res.data);
    },
    onSuccess(connection) {
      onSuccessConnection(connection);
    },
    onError(error) {
      console.error(error);
      setIsLoading(false);
    },
  });

  const editConnection = useMutation({
    mutationFn: (body: any) => {
      const additionalOptions = {};
      setIsLoading(true);
      if (body.url) additionalOptions['url'] = body.url;
      return request
        .put(`/api/connections/edit/${connection.id}`, {
          appSlug: app.slug,
          apiKey: body.apiKey,
          overrideUrl: body.overrideUrl,
          additionalOptions,
          alias: body.alias,
          authentication: body.authentication,
        })
        .then((res) => res.data);
    },
    onSuccess(connection) {
      onSuccessConnection(connection);
    },
    onError(error) {
      console.error(error);
      setIsLoading(false);
    },
  });

  const { mutate: createDatabaseConnection } = useCreateDatabaseConnection(onSuccessConnection, () =>
    setIsLoading(false)
  );

  const { mutate: updateDatabaseConnection } = useUpdateDatabaseConnection(onSuccessConnection, () =>
    setIsLoading(false)
  );

  const validateAlias = useCallback(
    (alias: string) => {
      const { isValid, errorHint } = validateName(alias, { allowSpaces: true, charLimit: 3 });
      if (isValid === false) {
        toast({
          duration: config.TOAST_DURATION,
          title: 'Invalid action name',
          description: errorHint,
          variant: 'destructive',
        });
        return false;
      }
      return true;
    },
    [toast]
  );

  const onConnect = ({
    alias,
    overrideUrl,
    apiKey,
    url,
    authentication,
  }: {
    alias: string;
    overrideUrl?: string;
    apiKey?: string;
    url?: string;
    authentication?: any;
  }) => {
    if (!validateAlias(alias)) return;

    if (!connection?.id && connectionAlias?.previousAliases.find((a: any) => a.alias === alias)) {
      toast({
        duration: config.TOAST_DURATION,
        title: 'Connection name already exists',
        description: 'Please choose a different name.',
        variant: 'destructive',
      });
      return;
    }

    const newConnection = {
      slug: app.slug,
      alias,
    };

    if (overrideUrl) newConnection['overrideUrl'] = overrideUrl;
    if (apiKey) newConnection['apiKey'] = apiKey;
    if (url) newConnection['url'] = url;
    if (authentication) newConnection['authentication'] = authentication;

    if (connection?.id) {
      editConnection.mutate(newConnection);
    } else {
      createConnection.mutate(newConnection);
    }
  };

  const onBasicAuthConnect = useCallback(
    (alias: string, data: BasicAuthFormData) => {
      if (!validateAlias(alias)) {
        return;
      }

      if (!connection?.id && connectionAlias?.previousAliases.find((a: any) => a.alias === alias)) {
        toast({
          duration: config.TOAST_DURATION,
          title: 'Connection name already exists',
          description: 'Please choose a different name.',
          variant: 'destructive',
        });
        return;
      }

      // TODO: AK -  I don't like this approach, we shouldn't be having another state for loading, but until we refactor all the connections, we will keep it like this
      setIsLoading(true);

      if (connection?.id) {
        editConnection.mutate({
          slug: app.slug,
          alias,
          authentication: {
            username: data.username,
            password: data.password,
          },
        });
      } else {
        createConnection.mutate({
          slug: app.slug,
          alias,
          authentication: {
            username: data.username,
            password: data.password,
          },
        });
      }
    },
    [
      app.slug,
      connection?.id,
      connectionAlias?.previousAliases,
      createConnection,
      editConnection,
      toast,
      validateAlias,
    ]
  );

  const onDatabaseConnect = useCallback(
    (alias: string, dbForm: DBConnectionFormData) => {
      if (!validateAlias(alias)) return;

      if (!connection?.id && connectionAlias?.previousAliases.find((a: any) => a.alias === alias)) {
        toast({
          duration: config.TOAST_DURATION,
          title: 'Connection name already exists',
          description: 'Please choose a different name.',
          variant: 'destructive',
        });
        return;
      }

      // TODO: AK -  I don't like this approach, we shouldn't be having another state for loading, but until we refactor all the connections, we will keep it like this
      setIsLoading(true);

      if (connection?.id) {
        updateDatabaseConnection({
          id: connection.id,
          dbFormData: dbForm,
        });
      } else {
        createDatabaseConnection({
          appSlug: app.slug,
          dbFormData: dbForm,
        });
      }
    },
    [
      validateAlias,
      connection?.id,
      connectionAlias?.previousAliases,
      toast,
      updateDatabaseConnection,
      app.slug,
      createDatabaseConnection,
    ]
  );

  const connectionExists = connection && connection.id;
  const buttonText = isLoading
    ? connectionExists
      ? 'Reconnecting...'
      : 'Connecting...'
    : connectionExists
    ? 'Reconnect'
    : 'Connect';

  const providerProps = {
    defaultAlias: connection?.id ? connection.alias : connectionAlias?.defaultAlias,
    isLoading,
    isFetchingAlias: connection?.id ? false : isLoadingAlias || isFetchingAlias || isRefetchingAlias,
    connection,
    buttonText,
    onClose,
  };

  // Change logic to switch case
  switch (app.slug) {
    case 'google':
    case 'google-mail':
    case 'google-sheet':
    case 'hubspot':
    case 'intercom':
    case 'jira':
    case 'salesforce':
    case 'slack':
    case 'slack':
    case 'smartsheet':
      return (
        <div className="h-full w-full">
          <OAuthProvider
            {...providerProps}
            onConnect={(alias, overrideUrl) => onConnect({ alias, overrideUrl })}
            // Intentionally not passing overrideUrlInfo to OAuthProvider
            overrideUrlInfo={null}
          />
        </div>
      );
    case 'zendesk':
      return (
        <div className="h-full w-full">
          <OAuthProvider
            {...providerProps}
            onConnect={(alias, overrideUrl) => onConnect({ alias, overrideUrl })}
            overrideUrlInfo={{
              label: 'Zendesk Base URL',
              placeholder: 'https://{domain}.zendesk.com',
              required: true,
            }}
          />
        </div>
      );

    case 'openai-chatgpt':
      return (
        <div className="h-full w-full">
          <OpenAIChatGPT
            {...providerProps}
            onConnect={(apiKey, alias) => onConnect({ alias, apiKey })}
            error={error}
          />
        </div>
      );

    case 'aws-ses':
      return (
        <div className="h-full w-full">
          <APIKeyConnection
            {...providerProps}
            onConnect={(apiKey, alias, overrideUrl) => onConnect({ alias, apiKey, overrideUrl })}
          />
        </div>
      );
    case 'lithic':
      return (
        <div className="h-full w-full">
          <APIKeyConnection
            {...providerProps}
            onConnect={(apiKey, alias, overrideUrl) => onConnect({ alias, apiKey, overrideUrl })}
            overrideUrlInfo={{
              placeholder: 'https://sandbox.lithic.com/v1',
              required: false,
            }}
          />
        </div>
      );

    case 'freshdesk':
      return (
        <div className="h-full w-full">
          <APIKeyConnection
            {...providerProps}
            onConnect={(apiKey, alias, overrideUrl) => onConnect({ alias, apiKey, overrideUrl })}
            overrideUrlInfo={{
              placeholder: 'https://domain.freshdesk.com/api/v2',
              required: true,
            }}
          />
        </div>
      );
    case 'taylor':
      return (
        <div className="h-full w-full">
          <APIKeyConnection
            {...providerProps}
            onConnect={(apiKey, alias, overrideUrl) => onConnect({ alias, apiKey, overrideUrl })}
            overrideUrlInfo={{
              placeholder: 'https://api.trytaylor.ai',
              required: false,
            }}
          />
        </div>
      );
    case 'sunshine':
      return (
        <div className="h-full w-full">
          <BasicAuthConnectionForm
            {...providerProps}
            onConnect={(data) => onBasicAuthConnect(data.connectionName, { ...data })}
            usernamePlaceholder="API Key"
            passwordPlaceholder="API Secret"
          />
        </div>
      );

    // Add other database providers here
    case 'redshift':
    case 'postgresql':
      return (
        <div className="h-full w-full">
          <DBConnectionForm
            {...providerProps}
            onConnect={(data) => onDatabaseConnect(data.connectionName, { ...data })}
          />
        </div>
      );
    case 'custom-http':
      return (
        <div className="h-full w-full">
          <CustomHttpConnection
            {...providerProps}
            onConnect={(url, alias, authentication) => onConnect({ alias, url, authentication })}
          />
        </div>
      );

    default:
      break;
  }

  return <div></div>;
}
