import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/app/components/ui/hover-card';
import { cn } from '@/utils';
import styles from './tooltip-card.module.css';

export default function TooltipCard({ trigger, content, triggerClassNames = '', contentClassNames = '' }) {
  return (
    <HoverCard openDelay={80} closeDelay={50}>
      <HoverCardTrigger asChild className={cn(triggerClassNames)}>
        {trigger}
      </HoverCardTrigger>
      <HoverCardContent className={cn(styles.content, 'font-detail font-medium', contentClassNames)}>
        <div className={styles.wrapper}>{content}</div>
      </HoverCardContent>
    </HoverCard>
  );
}
