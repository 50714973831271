'use client';

import { Button } from '@/app/components/ui/button';
import { FcGoogle } from 'react-icons/fc';
import { useStytchB2BClient } from '@stytch/nextjs/b2b';
import config from '@/config';
import styles from './continue-with-google.styles.module.css';
import { cn } from '@/utils';

export default function ContinueWithGoogle() {
  const stytchB2BClient = useStytchB2BClient();

  const startOAuth = async () => {
    await stytchB2BClient.oauth.google.discovery.start({
      discovery_redirect_url: config.HOST + '/api/auth/discovery',
    });
  };

  return (
    <Button
      id="auth-continue-with-google"
      variant="outline"
      onClick={startOAuth}
      className={cn(styles.button)}
    >
      <FcGoogle size="24px" />
      <span className={cn('leading-6 pr-3', 'text-slate-900 font-body font-medium')}>
        Continue with Google
      </span>
    </Button>
  );
}
