// @ts-strict-ignore
'use client';

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import inputStyles from '@/app/components/ui/input-field.styles.module.css';
import { cn } from '@/utils';
import styles from './providers.module.css';
import { InputWithIcon } from '../ui/input-with-icon';

export default function OpenAIChatGPT({
  onConnect,
  isLoading,
  error = '',
  defaultAlias,
  isFetchingAlias,
  buttonText,
  onClose,
}: {
  onConnect: (apiKey: string, alias: string) => void;
  isLoading: boolean;
  error?: string;
  defaultAlias?: string;
  isFetchingAlias?: boolean;
  buttonText: string;
  onClose?: () => void;
}) {
  const [show, setShow] = useState(null);
  const [apiKey, setApiKey] = useState('');
  const [connectionAlias, setConnectionAlias] = useState('');

  useEffect(() => {
    if (isFetchingAlias) return;
    if (defaultAlias) setConnectionAlias(defaultAlias);
  }, [isFetchingAlias, defaultAlias]);

  return (
    <div className={styles.container}>
      <div className={styles.frame}>
        <label htmlFor="slug" className="block font-detail font-medium leading-6 text-slate-900">
          Connection name
        </label>
        <Input
          onChange={(e) => setConnectionAlias(e.target.value)}
          value={isFetchingAlias ? '' : connectionAlias}
          disabled={isFetchingAlias}
          className={cn(inputStyles.inputField, 'font-detail')}
          data-1p-ignore
        />

        <div className="mt-4">
          <p className="text-slate-500 font-detail">
            <a
              className="text-black font-medium"
              href="https://platform.openai.com/account/api-keys"
              target="_blank"
            >
              Create an API key
            </a>{' '}
            and paste the key below (keys start with sk-).
          </p>
          <p className="text-slate-500 font-detail">
            If you get an error, you may have reached your OpenAI usage limits.
          </p>
          <p className="text-slate-500 font-detail">
            To view your usage, check your{' '}
            <a
              className="text-black font-medium"
              href="https://platform.openai.com/account/api-keys"
              target="_blank"
            >
              OpenAI billing settings
            </a>
            .
          </p>
        </div>

        <div className={cn(styles.frame, 'mt-4')}>
          <label htmlFor="email" className="block font-detail font-medium leading-6 text-slate-900">
            Open AI API KEY
          </label>
          <div className="relative rounded-md shadow-sm w-full">
            <InputWithIcon
              icon={null}
              type={show ? 'text' : 'password'}
              name="api-key"
              id="api-key"
              autoComplete="off"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              placeholder="sk-..."
              data-1p-ignore
            />
            <div className="pointer-cursor bg-slate-50 border absolute inset-y-0 right-0 flex items-center justify-center px-3">
              <button onClick={() => setShow(!show)} className="font-detail flex justify-center">
                {show ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>
        </div>
        <p
          className={classNames('text-red-700 font-detail', {
            hidden: !error,
          })}
        >
          {error}
        </p>
      </div>

      <div className={styles.buttonsFrame}>
        {onClose && (
          <Button
            onClick={onClose}
            disabled={isLoading}
            variant="ghost"
            className={cn({
              'opacity-50 cursor-not-allowed': isLoading,
            })}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={() => onConnect(apiKey, connectionAlias)}
          disabled={isLoading || !connectionAlias || !!error}
          className={cn({
            'opacity-50 cursor-not-allowed': isLoading,
          })}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
}
