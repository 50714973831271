'use client';
import { StytchProvider as StytchB2CProvider } from '@stytch/nextjs';
import { createStytchHeadlessClient } from '@stytch/nextjs/headless';

import { StytchB2BProvider } from '@stytch/nextjs/b2b';
import { createStytchB2BHeadlessClient } from '@stytch/nextjs/b2b';

import { ReactNode } from 'react';

/* This is for user/password */
const CustomStytchB2CProvider = ({ children }: { children: ReactNode }) => {
  const stytchB2CClient = createStytchHeadlessClient(process.env.NEXT_PUBLIC_STYTCH_B2C_PUBLIC_TOKEN || '');
  return <StytchB2CProvider stytch={stytchB2CClient}>{children}</StytchB2CProvider>;
};

/* This is for oauth */
const CustomStytchB2BProvider = ({ children }: { children: ReactNode }) => {
  const stytchB2BClient = createStytchB2BHeadlessClient(process.env.NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN || '');
  return <StytchB2BProvider stytch={stytchB2BClient}>{children}</StytchB2BProvider>;
};

const CustomStytchProvider = ({ children, sessionType }: { children: ReactNode; sessionType: any }) => {
  if (sessionType && sessionType.value === 'B2C') {
    return <CustomStytchB2CProvider>{children}</CustomStytchB2CProvider>;
  } else {
    return <CustomStytchB2BProvider>{children}</CustomStytchB2BProvider>;
  }
};

export { CustomStytchB2CProvider, CustomStytchB2BProvider };
export default CustomStytchProvider;
