// Only available in the browser as it relies on the window object

declare global {
  interface Window {
    NREUM: any;
  }
}

export function noticeError(error: Error, customAttributes?: object): void {
  if (typeof window.NREUM !== 'undefined' && typeof window.NREUM.noticeError === 'function') {
    window.NREUM.noticeError(error, customAttributes);
  } else {
    console.warn('New Relic is not installed or noticeError function is not available');
  }
}

export default {
  noticeError,
}