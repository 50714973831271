// @ts-strict-ignore
'use client';

import { useEffect, useCallback, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { Button } from '../../components/ui/button';
import { useStytchB2BClient, useStytchMember } from '@stytch/nextjs/b2b';
import Link from 'next/link';
import { Input } from '../../components/ui/input';
import { useMutation } from '@tanstack/react-query';
import { InputWithIcon } from '@/app/components/ui/input-with-icon';
import { cn } from '@/utils';
import { CheckBadgeIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { useStytch, useStytchUser } from '@stytch/nextjs';
import ContinueWithGoogle from '../components/continue-with-google';
import styles from './login.styles.module.css';
import config from '@/config';
import ErrorMessage from '../components/error-message';
import { generateQueryParameter } from '../shared/email-encode-decode';
import request from '@/lib/clients/request';

export default function LoginPage({ userPasswordEnabled = false }: { userPasswordEnabled?: boolean }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [magicLinkLoading, setMagicLinkLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [passwordTooltip, setPasswordTooltip] = useState<string | null>(null);
  const [validPassword, setValidPassword] = useState<boolean>(false);
  const [icon, setIcon] = useState<React.ReactNode | null>(null);
  const router = useRouter();
  const stytch = useStytch();
  const stytchB2B = useStytchB2BClient();
  const searchParams = useSearchParams();
  const { member, isInitialized: isMemberInitialized } = useStytchMember();
  const { user, isInitialized: isUserInitialized } = useStytchUser();

  const { mutateAsync: signInPassword, isLoading } = useMutation({
    mutationFn: () =>
      request
        .post('/api/auth/password', { email, password })
        .then((response) => {
          window.location.href = response.data.redirectURL;
        })
        .catch((err) => {
          setErrorMessage(err.message);
        }),
  });

  const checkPasswordStrength = useCallback(
    async (password: string) => {
      if (!password) return;
      const pwsc = await stytch.passwords.strengthCheck({
        password: password,
      });

      setPasswordTooltip(
        pwsc.feedback.warning ||
          pwsc.feedback.suggestions.join(' ') ||
          (pwsc.breached_password && 'Password is not strong enough') ||
          'Password is strong'
      );
      setValidPassword(pwsc.valid_password);

      if (pwsc.valid_password) {
        setIcon(<CheckBadgeIcon className="text-green-500 w-6" />);
      } else {
        setIcon(<InformationCircleIcon className="text-red-500 w-6" />);
      }
    },
    [password]
  );

  // If the Stytch SDK has a User then redirect to home page
  useEffect(() => {
    if ((isMemberInitialized && member) || (isUserInitialized && user)) {
      router.replace('/workflows');
    }
  }, [member, user, isMemberInitialized, isUserInitialized, router]);

  // Trigger password strength check
  useEffect(() => {
    if (!password) setIcon(null);
    const timeout = setTimeout(() => {
      checkPasswordStrength(password);
    }, 120);
    return () => clearTimeout(timeout);
  }, [password]);

  useEffect(() => {
    if (!searchParams) return;

    const error = searchParams.get('error');
    if (error && error === 'invalid_domain') {
      setErrorMessage('This email address cannot be used. Please use your work email.');
    } else if (error && error === 'invalid_magic_link') {
      setErrorMessage('Magic link is invalid or expired. Please try again.');
    }
  }, [searchParams]);

  const onSignIn = async () => {
    if (!validPassword) return;
    await signInPassword();
  };

  const onMagicLink = async () => {
    setMagicLinkLoading(true);
    try {
      await stytchB2B.magicLinks.email.discovery.send({
        email_address: email,
        discovery_redirect_url: config.HOST + '/api/auth/discovery',
      });
      const queryParams = generateQueryParameter(email);
      router.push('/auth/magic-link' + queryParams);
    } catch (error) {
      const invalid_email_regex = /invalid_email/;
      if (invalid_email_regex.test(error.message)) {
        setErrorMessage('Invalid email address.');
      }
      setMagicLinkLoading(false);
    }
  };

  return (
    <div className={cn(styles.container)}>
      <div className={cn(styles.signupContainer)}>
        <div className={styles.header}>
          <p className={cn(styles.title)}>Welcome back</p>
          <p className={cn(styles.subtitle, 'font-body')}>
            New to Strada?{' '}
            <Link href="/auth/signup" className="underline">
              Sign up
            </Link>
          </p>
        </div>

        <div className={cn(styles.formContainer)}>
          <ContinueWithGoogle />
          <div className="relative w-full">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-slate-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-2 font-detail font-detail text-slate-500">OR</span>
            </div>
          </div>
          {userPasswordEnabled ? (
            <div className={cn(styles.emailContainer, 'w-full')}>
              <Input
                className="w-full font-body font-normal text-slate-900 placeholder:text-slate-400"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="name@example.com"
                type="email"
                required
              />
              <InputWithIcon
                className={cn('w-full font-body font-normal text-slate-900 placeholder:text-slate-400', {
                  'border-red-500': password && !validPassword,
                })}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                placeholder="password"
                type="password"
                required
                icon={icon}
                tooltip={passwordTooltip}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') onSignIn();
                }}
              />
              <div className="w-full flex flex-col gap-2">
                <Button
                  id="auth-sign-up"
                  onClick={() => onSignIn()}
                  className="w-full font-body font-normal"
                  disabled={password && (!validPassword || isLoading)}
                >
                  {isLoading ? 'Continuing with email...' : 'Continue with email'}
                </Button>
                {errorMessage && <ErrorMessage message={errorMessage} />}
              </div>
            </div>
          ) : (
            <div className={cn(styles.emailContainer, 'w-full')}>
              <Input
                className="w-full font-body font-normal text-slate-900 placeholder:text-slate-400"
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onMagicLink();
                  }
                }}
                value={email}
                placeholder="name@example.com"
                type="email"
              />
              <div className="w-full flex flex-col gap-2">
                <Button
                  id="auth-sign-in-button-magic-link"
                  onClick={() => onMagicLink()}
                  className="w-full font-body font-normal"
                  disabled={magicLinkLoading}
                >
                  {magicLinkLoading ? 'Continuing with email...' : 'Continue with email'}
                </Button>
                {errorMessage && <ErrorMessage message={errorMessage} />}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
