// @ts-strict-ignore
'use client';

import { Dialog } from '@/app/components/ui/dialog';
import CreateOrCloneWorkflowDialog from './dialogs/create-or-clone-workflow';
import AddOrChooseConnectionDialog from './dialogs/add-or-choose-connection';
import { nanoid } from 'nanoid';
import React, { useCallback } from 'react';
import NewConnectionDialog from './dialogs/new-connection';
import GTM from '@/utils/gtm';
import { useAppDispatch, useAppSelector } from '@/lib/redux/hooks';
import { closeGlobalDialog } from '@/app/workflows/sliceWorkflowsPage';

export default function GlobalDialog() {
  const dispatch = useAppDispatch();
  const workflowsPageState = useAppSelector((state) => state.workflowsPage);

  const closeAndRedirect = useCallback(() => {
    workflowsPageState.onCloseModalRedirect();
    dispatch(closeGlobalDialog());
  }, [dispatch, workflowsPageState]);

  const close = useCallback(() => {
    dispatch(closeGlobalDialog());
  }, [dispatch]);

  return (
    <>
      <Dialog
        key={nanoid()}
        open={workflowsPageState.isWorkflowDialogOpen}
        onOpenChange={(open) => !open && closeAndRedirect()} // This is controlled by the x mark or clicking outside the dialog
      >
        <CreateOrCloneWorkflowDialog
          onWorkflowCreated={workflowsPageState.onWorkflowCreated}
          open={workflowsPageState.isWorkflowDialogOpen}
          duplicateFrom={workflowsPageState.duplicateWorkflowData}
          onClose={closeAndRedirect} // This is controlled by the cancel button
        />
      </Dialog>
      <Dialog
        key={nanoid()}
        open={workflowsPageState.isConnectionsDialogOpen}
        onOpenChange={(open) => {
          // This is controlled by the skip button or clicking outside the dialog
          if (!open) {
            GTM.pushObjectToDataLayer({
              event: 'skip-add-connection',
              description: 'User clicked the skip button in the connections dialog',
            });
            close();
          }
        }}
      >
        <AddOrChooseConnectionDialog isActive={workflowsPageState.isConnectionsDialogOpen} onClose={close} />
      </Dialog>
      {workflowsPageState.newConnectionTriggerData?.app.id && (
        <Dialog
          key={nanoid()}
          open={workflowsPageState.isNewConnectionDialogOpen}
          onOpenChange={(open) => !open && close()} // This is controlled by the x mark or clicking outside the dialog
        >
          <NewConnectionDialog onClose={close} />
        </Dialog>
      )}
    </>
  );
}
