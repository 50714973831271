import * as React from 'react';

import { cn } from '@/utils';
import { InputProps } from './input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './tooltip';
import inputStyles from './input-field.styles.module.css';
import TooltipCard from '../tooltip-card';

export type InputWithIconProps = React.InputHTMLAttributes<HTMLInputElement>;

interface InputWithIcon extends InputProps {
  icon: React.ReactNode;
  tooltip?: string;
}

const InputWithIcon = React.forwardRef<HTMLInputElement, InputWithIcon>(
  ({ className, icon, tooltip, ...props }, ref) => {
    return (
      <div
        className={cn(
          'flex h-10 items-center rounded-md border border-input bg-white font-detail ring-offset-background focus-within:ring-0 focus-within:ring-ring',
          inputStyles.inputField,
          className
        )}
      >
        <input
          {...props}
          ref={ref}
          className={cn(
            'flex w-full border-0 rounded-md bg-transparent transition-colors file:font-detail file:font-medium placeholder:text-slate-400 focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50',
            'p-0',
            className
          )}
        />
        {tooltip ? (
          <TooltipCard
            trigger={<div className="mr-3 cursor-pointer">{icon}</div>}
            content={<span className="font-detail z-50 p-1.5 rounded-sm">{tooltip}</span>}
          />
        ) : (
          <>{icon}</>
        )}
      </div>
    );
  }
);

InputWithIcon.displayName = 'InputWithIcon';

export { InputWithIcon };
