// @ts-strict-ignore
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  WorkflowFunctionWithApp,
  WorkflowFunctionWithConnection,
  WorkflowsPageStateType,
} from '@/context/types/workflows-page';
import { getAppFromWorkflowFunction } from '@/models/workflow-config';

export const initialState: WorkflowsPageStateType = {
  connections: [],
  connectWithApp: null,
  duplicateWorkflowData: null,
  isConnectionsDialogOpen: false,
  isNewConnectionDialogOpen: false,
  isNewWorkflow: false,
  isWorkflowDialogOpen: false,
  newConnectionTriggerData: null,
  workflows: [],

  changeConnectionInitiated: null,

  onWorkflowCreated: () => null,
  onCloseModalRedirect: () => null,
};

export const sliceWorkflowsPage = createSlice({
  name: 'workflowsPage',
  initialState,
  reducers: {
    setInitialWorkflows: (state, action: PayloadAction<any>) => {
      state.workflows = action.payload;
      state.isWorkflowDialogOpen = false;
    },
    setInitialConnections: (state, action: PayloadAction<any>) => {
      state.connections = action.payload;
    },
    updateWorkflow: (state, action: PayloadAction<any>) => {
      state.workflows = state.workflows.map((workflow) =>
        workflow.id === action.payload.id ? action.payload : workflow
      );
    },
    addWorkflow: (state, action: PayloadAction<any>) => {
      state.workflows = [...state.workflows, action.payload];
      state.isWorkflowDialogOpen = false;
      state.isConnectionsDialogOpen = true;
      state.isNewWorkflow = true;
    },
    addDuplicatedWorkflow: (state, action: PayloadAction<any>) => {
      state.workflows = [...state.workflows, action.payload];
      state.isWorkflowDialogOpen = false;
      state.isConnectionsDialogOpen = false;
      state.isNewWorkflow = false;
    },
    addClonedWorkflow: (state, action: PayloadAction<any>) => {
      state.workflows = [...state.workflows, action.payload];
      state.isWorkflowDialogOpen = false;
      state.isConnectionsDialogOpen = false;
      state.isNewWorkflow = true;
    },
    deleteWorkflow: (state, action: PayloadAction<string>) => {
      state.workflows = state.workflows.filter((workflow) => workflow.id !== action.payload);
    },
    openWorkflowModal: (state, action: PayloadAction<any>) => {
      state.isWorkflowDialogOpen = true;
      state.duplicateWorkflowData = action.payload.duplicateWorkflowData;
      state.onWorkflowCreated = action.payload.onWorkflowCreated;
      state.onCloseModalRedirect = action.payload.onCloseModalRedirect;
    },
    openConnectionsDialogExistingWorkflow: (state) => {
      state.isConnectionsDialogOpen = true;
      state.isNewWorkflow = false;
    },
    openChangeConnectionDialog: (state) => {
      state.isConnectionsDialogOpen = true;
      state.isNewWorkflow = false;
    },
    openFilteredConnectionsDialog: (state, action: PayloadAction<WorkflowFunctionWithApp>) => {
      state.isConnectionsDialogOpen = true;
      state.isNewWorkflow = false;
      state.connectWithApp = action.payload;
    },
    closeGlobalDialog: (state) => {
      state.changeConnectionInitiated = null;
      state.connectWithApp = null;
      state.isConnectionsDialogOpen = false;
      state.isNewConnectionDialogOpen = false;
      state.isWorkflowDialogOpen = false;
    },
    setIsNewWorkflow: (state, action: PayloadAction<boolean>) => {
      state.isNewWorkflow = action.payload;
    },
    openNewConnectionDialog: (state, action: PayloadAction<any>) => {
      // Payload should be a workflow function
      const { id } = action.payload;
      const app = getAppFromWorkflowFunction(action.payload);

      // This is for handling the case where the required data is not passed in
      if (!app || !id) throw new Error('Missing app or id in OPEN_NEW_CONNECTION_DIALOG action');

      state.isConnectionsDialogOpen = false;
      state.isNewConnectionDialogOpen = true;
      // Temporary fix, the incoming payload should have the app
      // Should fix with this ticket:https://linear.app/strada/issue/STR-1142/add-app-column-to-workflow-function
      // We override the app with the one we get from the workflow function
      state.newConnectionTriggerData = { ...action.payload, app };
    },
    setChangeConnectionInitiated: (
      state,
      action: PayloadAction<WorkflowFunctionWithConnection | WorkflowFunctionWithApp>
    ) => {
      // Payload should be a workflow function with either a connection or an app
      state.changeConnectionInitiated = action.payload;
      state.isConnectionsDialogOpen = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { name: SLICE_WORKFLOWS_PAGE_NAME } = sliceWorkflowsPage;
export const {
  addClonedWorkflow,
  addDuplicatedWorkflow,
  addWorkflow,
  closeGlobalDialog,
  deleteWorkflow,
  openChangeConnectionDialog,
  openConnectionsDialogExistingWorkflow,
  openFilteredConnectionsDialog,
  openNewConnectionDialog,
  openWorkflowModal,
  setChangeConnectionInitiated,
  setInitialConnections,
  setInitialWorkflows,
  setIsNewWorkflow,
  updateWorkflow,
} = sliceWorkflowsPage.actions;
export default sliceWorkflowsPage.reducer;
