declare global {
  interface Window {
    Intercom: any;
  }
}

// Create a class to handle Google Tag Manager
export default class Intercom {
  private static app_id = 'f90rulmf';
  private static STARTER_TOUR_PART_ONE_ID = '504962';
  private static STARTER_TOUR_PART_TWO_ID = '504963';
  private static STARTER_TOUR_PART_ONE_ONDEMAND_ID = 506265; // This is the ID for the on-demand tour - it MUST be a number
  private static HELP_MESSAGE = 'Hi, I have a question:';
  private static FEEDBACK_MESSAGE = 'Hi, I have a suggestion:';

  static init() {
    if (!window.Intercom) {
      console.warn('Intercom is not defined');
      return;
    }
  }

  static boot() {
    console.log('Intercom::boot');
    this.init();
    window.Intercom('boot', {
      app_id: this.app_id,
    });
  }

  static shutdown() {
    console.log('Intercom::shutdown');
    this.init();
    window.Intercom('shutdown');
  }

  static update(user) {
    console.log('Intercom::update');
    this.init();
    window.Intercom('update', {
      app_id: this.app_id,
      email: user?.email,
      name: user?.name,
      user_id: user?.id,
      user_hash: user?.intercomIdentityHash,
      hide_default_launcher: false,
    });
  }

  static hideLauncher() {
    console.log('Intercom::hideLauncher');
    this.init();
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }

  static triggerStarterTourPartOne(cb?: () => void) {
    console.log('Intercom::triggerTourPt1');
    this.init();
    window.Intercom('startTour', this.STARTER_TOUR_PART_ONE_ONDEMAND_ID);
    if (cb) cb();
  }

  static triggerStarterTourPartTwo() {
    console.log('Intercom::triggerTourPt2');
    this.init();
    window.Intercom('trackEvent', 'trigger_tour', {
      tour_id: this.STARTER_TOUR_PART_TWO_ID,
    });
  }

  static provideFeedback() {
    console.log('Intercom::feedback');
    this.init();
    window.Intercom('showNewMessage', this.FEEDBACK_MESSAGE);
  }

  static askForHelp() {
    console.log('Intercom::help');
    this.init();
    window.Intercom('showNewMessage', this.HELP_MESSAGE);
  }
}
