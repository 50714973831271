// @ts-strict-ignore
/* eslint-disable react-hooks/rules-of-hooks */
'use client';

import ScheduleSelector from '@/app/components/schedule-selector';
import { Button } from '@/app/components/ui/button';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/app/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/app/components/ui/form';
import { Input } from '@/app/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/app/components/ui/select';
import config from '@/config';
import ScheduleSelectMinute from '../../workflows/components/schedule-select-minute';
import ScheduleSelectHour from '../../workflows/components/schedule-select-hour';
import ScheduleSelectorWeekday from '../../workflows/components/schedule-select-weekday';
import ScheduleSelectorDay from '../../workflows/components/schedule-select-day';
import CronNextExecutions from '@/app/components/cron-next-executions';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { cn } from '@/utils';
import { DuplicateFromParams, FormSchema, defaultScheduleFormValues } from './workflowCreation';
import { TemplateDetails } from '@/models/template';
import styles from './create-or-clone-workflow.styles.module.css';
import { tz } from 'moment-timezone';
import request from '@/lib/clients/request';

type ModalProps = {
  open: boolean;
  onWorkflowCreated: (workflow: any) => void;
  duplicateFrom?: DuplicateFromParams;
  onClose?: () => void;
};

// Requires to be wrapped in a Dialog component
export default function CreateOrCloneWorkflowModal({
  open,
  onWorkflowCreated,
  duplicateFrom,
  onClose,
}: ModalProps) {
  const [loading, setLoading] = useState(false);
  const [invalidTime, setInvalidTime] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // @ts-ignore
  const [scheduleCron, setScheduleCron] = useState(duplicateFrom?.schedule || '');
  const [scheduleOptionDisabled, setScheduleOptionDisabled] = useState(false);
  const [triggerOptionDisabled, setTriggerOptionDisabled] = useState(false);

  const { createTriggeredWorkflow, createScheduledWorkflow } = getCreateApiActions();
  const duplicateWorkflow = getDuplicateApiAction(duplicateFrom?.id);
  const cloneFromTemplate = getCloneFromTemplateApiAction(duplicateFrom?.id);

  const { data: workflowToDuplicate } = useQuery({
    queryKey: ['duplicateFromWorkflowName', duplicateFrom?.id],
    queryFn: () => request.get(`/api/workflows/${duplicateFrom?.id}/duplicate`).then((res) => res.data),
    enabled: open && !!duplicateFrom?.id && duplicateFrom?.source === 'workflows',
  });

  const { data: template } = useQuery<TemplateDetails>({
    queryKey: ['template', duplicateFrom?.id],
    queryFn: () => request.get(`/api/templates/${duplicateFrom?.id}`).then((res) => res.data),
    enabled: open && !!duplicateFrom?.id && duplicateFrom?.source === 'templates',
  });

  let defaultFormValues = defaultScheduleFormValues as Partial<typeof defaultScheduleFormValues>;

  // This sets the default trigger to Webhook
  defaultFormValues.trigger = 'triggered';

  if (duplicateFrom) {
    defaultFormValues = {
      ...defaultFormValues,
      name: duplicateFrom.name,
      trigger: duplicateFrom.type,
    };
    if (duplicateFrom.type === 'scheduled' && duplicateFrom.custom) {
      const [_second, minute, hour, day, _month, weekday] = duplicateFrom.custom.split(' ');

      defaultFormValues = {
        name: duplicateFrom.name,
        trigger: duplicateFrom.type,
        schedule: 'custom',
        minute,
        hour,
        weekday,
        day,
        custom: duplicateFrom.custom,
      };
    }
  }

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    const subscription = form.watch((data) => {
      let schedule = '';
      if (data.trigger === 'scheduled') {
        if (data.schedule === 'hourly') {
          schedule = `0 ${data.minute} * * * *`;
        } else if (data.schedule === 'daily') {
          schedule = `0 0 ${data.hour} * * *`;
        } else if (data.schedule === 'weekly') {
          schedule = `0 0 ${data.hour} * * ${data.weekday}`;
        } else if (data.schedule === 'monthly') {
          schedule = `0 0 ${data.hour} ${data.day} * *`;
        } else if (data.schedule === 'custom') {
          schedule = data.custom;
        }
        setScheduleCron(schedule);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [scheduleCron, form.watch]);

  useEffect(() => {
    if (duplicateFrom?.id && duplicateFrom?.source === 'workflows' && workflowToDuplicate) {
      form.setValue('name', workflowToDuplicate.name);
    }
  }, [workflowToDuplicate, duplicateFrom, form]);

  useEffect(() => {
    if (duplicateFrom?.id && duplicateFrom?.source === 'templates' && template) {
      setScheduleOptionDisabled(template.workflowType === 'triggered');
      setTriggerOptionDisabled(template.workflowType === 'scheduled');
      form.setValue('name', template.title);
    }
  }, [duplicateFrom?.id, duplicateFrom?.source, form, template]);

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setErrorMessage('');
    if (data.name === '' || invalidTime) {
      setErrorMessage('Please fill in all required fields');
      return;
    }
    setLoading(true);

    let workflow = null;

    try {
      if (duplicateFrom && duplicateFrom.source === 'workflows') {
        if (data.trigger === 'scheduled') {
          workflow = await duplicateWorkflow.mutateAsync({
            name: data.name,
            schedule: scheduleCron,
            // TODO - get the timezone from the user explicitly
            timezone: tz.guess(),
          });
        } else if (data.trigger === 'triggered') {
          workflow = await duplicateWorkflow.mutateAsync({ name: data.name });
        }
      } else if (duplicateFrom && duplicateFrom.source === 'templates') {
        workflow = await cloneFromTemplate.mutateAsync({
          name: data.name,
          schedule: scheduleCron,
          // TODO - get the timezone from the user explicitly
          timezone: tz.guess(),
        });
      } else if (data.trigger === 'scheduled') {
        workflow = await createScheduledWorkflow.mutateAsync({
          name: data.name,
          schedule: scheduleCron,
          // TODO - get the timezone from the user explicitly
          timezone: tz.guess(),
        });
      } else if (data.trigger === 'triggered') {
        workflow = await createTriggeredWorkflow.mutateAsync({
          name: data.name,
        });
      }

      if (!workflow) return;
      if (workflow.error) throw new Error(workflow.message);

      onWorkflowCreated(workflow);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  let submitButtonText = duplicateFrom && duplicateFrom.source === 'workflows' ? 'Duplicate' : 'Next';
  let submitButtonExecutingText =
    duplicateFrom && duplicateFrom.source === 'workflows' ? 'Duplicating...' : 'Creating...';

  return (
    <DialogContent
      className={cn(styles.container, 'font-detail')}
      overrideClose={<></>}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onInteractOutside={(event) => event.preventDefault()}
    >
      <DialogHeader>
        <DialogTitle>
          {duplicateFrom && duplicateFrom.source === 'workflows' ? 'Duplicate workflow' : 'New workflow'}
        </DialogTitle>
        <DialogDescription>Name your workflow and choose a trigger.</DialogDescription>
      </DialogHeader>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={cn(styles.formContainer, 'space-y-4')}>
          {/* Name */}
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="grid grid-cols-12 space-y-0 space-x-4">
                <div className="col-span-2 flex items-center justify-end">
                  <FormLabel>Name</FormLabel>
                </div>
                <div className="col-span-10 items-center justify-start font-detail">
                  <Input
                    className="font-detail"
                    id="new-workflow-dialog-name-input"
                    onKeyDownCapture={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                        form.handleSubmit(onSubmit)();
                      }
                    }}
                    {...field}
                    data-1p-ignore
                  />
                </div>
                <FormMessage className="col-span-10 pt-2" />
              </FormItem>
            )}
          />

          {/* Trigger */}
          <FormField
            control={form.control}
            name="trigger"
            render={({ field }) => (
              <FormItem className="grid grid-cols-12 space-y-0 space-x-4">
                <div className="col-span-2 flex items-center justify-end">
                  <FormLabel>Trigger</FormLabel>
                </div>
                <div className="col-span-10 items-center justify-start">
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger id="new-workflow-dialog-trigger-dropdown">
                        <SelectValue placeholder="Select the type of trigger" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="max-h-40">
                      <SelectItem disabled={scheduleOptionDisabled} value="scheduled">
                        Schedule
                      </SelectItem>
                      <SelectItem disabled={triggerOptionDisabled} value="triggered">
                        Webhook
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <FormMessage className="col-span-10 pt-2" />
              </FormItem>
            )}
          />

          {/* Schedule */}
          {form.watch('trigger') === 'scheduled' && (
            <FormField
              control={form.control}
              name="schedule"
              render={({ field }) => (
                <FormItem className="grid grid-cols-12 space-y-0 space-x-4">
                  <div className="col-span-2 flex items-center justify-end">
                    <FormLabel>Schedule</FormLabel>
                  </div>
                  <div className="col-span-10 items-center justify-start">
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent className="max-h-40">
                        {config.SCHEDULE_INTERVAL_OPTIONS.map((interval) => (
                          <SelectItem key={interval.value} value={interval.value}>
                            {interval.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <FormMessage className="col-span-10 pt-2" />
                </FormItem>
              )}
            />
          )}
          {form.watch('trigger') === 'scheduled' && form.watch('schedule') === 'hourly' && (
            <ScheduleSelectMinute form={form} />
          )}

          {/* Schedule - Daily */}
          {form.watch('trigger') === 'scheduled' && form.watch('schedule') === 'daily' && (
            <ScheduleSelectHour form={form} />
          )}

          {/* Schedule - Weekly */}
          {form.watch('trigger') === 'scheduled' && form.watch('schedule') === 'weekly' && (
            <>
              <ScheduleSelectorWeekday form={form} />
              <ScheduleSelectHour form={form} />
            </>
          )}

          {/* Schedule - Monthly */}
          {form.watch('trigger') === 'scheduled' && form.watch('schedule') === 'monthly' && (
            <>
              <ScheduleSelectorDay form={form} />
              <ScheduleSelectHour form={form} />
            </>
          )}

          {/* Schedule - Custom (Cron) */}
          {form.watch('trigger') === 'scheduled' && form.watch('schedule') === 'custom' && (
            <FormField
              control={form.control}
              name="custom"
              render={({ field }) => (
                <FormItem className="grid grid-cols-12 space-y-0 space-x-4">
                  <div className="col-span-2 flex items-start justify-end">
                    <FormLabel>Cron</FormLabel>
                  </div>
                  <div className="col-span-10 items-center justify-start">
                    <ScheduleSelector
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                      onError={setInvalidTime}
                    />
                  </div>
                  <FormMessage className="col-span-10 pt-2" />
                </FormItem>
              )}
            />
          )}

          {form.watch('trigger') === 'scheduled' && form.watch('schedule') !== 'custom' && scheduleCron && (
            <div className="grid grid-cols-12 space-y-0 space-x-4">
              <div className="col-span-2 flex items-start justify-end"></div>
              <div className="col-span-10 items-center justify-start">
                <CronNextExecutions expression={scheduleCron} />
              </div>
            </div>
          )}

          <div className="flex items-center justify-end space-x-3">
            {errorMessage && <span className="text-red-500 font-detail">{errorMessage}</span>}
            <Button
              id="new-workflow-dialog-cancel-button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClose();
              }}
              variant="ghost"
            >
              Cancel
            </Button>
            <Button
              id={duplicateFrom ? 'new-workflow-dialog-duplicate-button' : 'new-workflow-dialog-next-button'}
              type="submit"
              disabled={duplicateFrom ? false : loading || invalidTime || !form.getFieldState('name').isDirty}
            >
              {loading ? submitButtonExecutingText : submitButtonText}
            </Button>
          </div>
        </form>
      </Form>
    </DialogContent>
  );
}

const getCreateApiActions = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const createTriggeredWorkflow = useMutation({
    mutationFn: (body: any) => request.post('/api/workflows/triggered/create', body).then((res) => res.data),
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const createScheduledWorkflow = useMutation({
    mutationFn: (body: any) => request.post('/api/workflows/scheduled/create', body).then((res) => res.data),
  });

  return {
    createTriggeredWorkflow,
    createScheduledWorkflow,
  };
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const getDuplicateApiAction = (workflowId: string) =>
  useMutation({
    mutationFn: (body: any) =>
      request.post(`/api/workflows/${workflowId}/duplicate`, body).then((res) => res.data),
  });

const getCloneFromTemplateApiAction = (templateId: string) =>
  useMutation({
    mutationFn: (body: { name: string; schedule: string; timezone: string }) =>
      request.post(`/api/templates/${templateId}/clone`, body).then((res) => res.data),
  });
