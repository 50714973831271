import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Workflow, Trigger, Action } from '@strada/workflow-service/src/api/v1/workflow/types';
import { ExecutionStep } from '@strada/workflow-service/src/services/workflowExecutor/types';
import { APP_PLACEHOLDER_DATA, ACTION_PLACEHOLDER_DATA } from './constants';

type SelectedBlock = { type: 'action' | 'trigger'; id: number };

type NoCodeBuilderStateType = {
  autoSaving: boolean;
  activePanel?: 'workflowStep' | 'executions';
  selectedBlock?: SelectedBlock;
  workflow?: Workflow;
  executionLogs?: ExecutionStep[];
  triggersPayload: Record<number, any>;
  actionsPayload: Record<number, any>;
};

export const initialState: NoCodeBuilderStateType = {
  autoSaving: false,
  triggersPayload: {},
  actionsPayload: {},
};

const NO_CODE_BUILDER_SLICE_NAME = 'noCodeBuilder';

export const sliceNoCodeBuilder = createSlice({
  name: NO_CODE_BUILDER_SLICE_NAME,
  initialState,
  reducers: {
    pageMounted: (state, action: PayloadAction<Workflow>) => {
      state.workflow = action.payload;
      action.payload.triggers.forEach((trigger, idx) => {
        state.triggersPayload[idx] = APP_PLACEHOLDER_DATA[trigger.app.slug || ''] || {};
      });
      action.payload.orderedActions.forEach((ordAction, idx) => {
        state.actionsPayload[idx] = ACTION_PLACEHOLDER_DATA[ordAction.actionSlug] || {};
      });
    },
    autoSavingStarted: (state) => {
      state.autoSaving = true;
    },
    autoSavingCompleted: (state) => {
      state.autoSaving = false;
    },
    triggerAdded: (state, action: PayloadAction<Trigger>) => {
      if (state.workflow) {
        state.workflow.triggers = [action.payload];
        state.activePanel = 'workflowStep';
        state.selectedBlock = { type: 'trigger', id: action.payload.id };
        state.triggersPayload[0] = APP_PLACEHOLDER_DATA[action.payload.app.slug || ''] || {};
      }
    },
    triggerPayloadReceived: (state, action: PayloadAction<any>) => {
      state.triggersPayload[0] = action.payload;
    },
    testRunInitiated: (state) => {
      state.executionLogs = [];
      state.activePanel = 'executions';
    },
    testStepCompleted: (state, action: PayloadAction<ExecutionStep>) => {
      if (state.executionLogs) {
        state.executionLogs.push(action.payload);
      }
    },
    actionAdded: (state, action: PayloadAction<Action>) => {
      if (state.workflow) {
        state.workflow.orderedActions.push(action.payload);
        state.activePanel = 'workflowStep';
        state.selectedBlock = { type: 'action', id: action.payload.id! };
        state.actionsPayload[state.workflow.orderedActions.length - 1] =
          ACTION_PLACEHOLDER_DATA[action.payload.actionSlug] || {};
      }
    },
    blockSelected: (state, action: PayloadAction<SelectedBlock | undefined>) => {
      state.activePanel = 'workflowStep';
      state.selectedBlock = action.payload;
    },
    workflowNameUpdated: (state, action: PayloadAction<string>) => {
      if (state.workflow) {
        state.workflow.name = action.payload;
      }
    },
    triggerConfigUpdated: (
      state,
      action: PayloadAction<{
        triggerEventSlug: string;
      }>
    ) => {
      const trigger = state.workflow?.triggers[0];
      if (trigger) {
        // @ts-ignore
        trigger.eventSlug = action.payload.triggerEventSlug;
      }
    },
    actionConfigUpdated: (state, action: PayloadAction<Record<string, unknown>>) => {
      const currentAction = state.workflow?.orderedActions.find(
        (action) => action.id === state.selectedBlock?.id
      );
      if (currentAction) {
        currentAction.input = action.payload;
      }
    },
    pageUnmounted: () => {
      return { ...initialState };
    },
  },
});

// Action creators are generated for each case reducer function
export const { name: SLICE_NO_CODE_BUILDER_NAME } = sliceNoCodeBuilder;
export const {
  pageMounted,
  autoSavingStarted,
  autoSavingCompleted,
  triggerAdded,
  triggerPayloadReceived,
  testRunInitiated,
  testStepCompleted,
  actionAdded,
  blockSelected,
  workflowNameUpdated,
  triggerConfigUpdated,
  actionConfigUpdated,
  pageUnmounted,
} = sliceNoCodeBuilder.actions;
export default sliceNoCodeBuilder.reducer;
