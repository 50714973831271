import { cn } from '@/utils';
import Image from 'next/image';
import {
  AwsSes,
  Freshdesk,
  Gmail,
  Google,
  Hubspot,
  Intercom,
  Jira,
  KeyValueStore,
  Lithic,
  OpenAI,
  PostgreSQL,
  Redshift,
  RestAPI,
  Sheets,
  Slack,
  StradaEmail,
  Sunshine,
  Taylor,
  Zendesk,
} from '.';
import { StradaDemoApp } from '@strada/types';
import styles from './app-logo.module.css';
import { FilterIcon, GlobeIcon, StrikethroughIcon, WebhookIcon, PlayIcon } from 'lucide-react';

const LogoContainer = ({ className = '', style = {}, children }) => {
  return (
    <div className={cn(styles.logoContainer, className)} style={style}>
      {children}
    </div>
  );
};

export default function AppLogo({
  slug,
  className,
  size,
}: {
  slug: string;
  className?: string;
  size?: number;
}) {
  switch (slug) {
    case 'aws-ses':
      return <AwsSes dimensionPx={size || 32} />;
    case 'custom-http':
      return <RestAPI dimensionPx={size || 32} />;
    case 'freshdesk':
      return <Freshdesk dimensionPx={size || 32} />;
    case StradaDemoApp:
      return <StradaEmail dimensionPx={size || 32} />;
    case 'google':
      return <Google dimensionPx={size || 32} />;
    case 'google-mail':
      return <Gmail dimensionPx={size || 32} />;
    case 'google-sheet':
      return <Sheets dimensionPx={size || 32} />;
    case 'hubspot':
      return <Hubspot dimensionPx={size || 32} />;
    case 'intercom':
    case 'intercom.add-private-note':
      return <Intercom dimensionPx={size || 32} />;
    case 'jira':
      return <Jira dimensionPx={size || 32} />;
    case 'lithic':
      return <Lithic dimensionPx={size || 32} />;
    case 'openai-chatgpt':
      return <OpenAI dimensionPx={size || 32} />;
    case 'slack':
      return <Slack dimensionPx={size || 32} />;
    case 'zendesk':
      return <Zendesk dimensionPx={size || 32} />;
    case 'redshift':
      return <Redshift dimensionPx={size || 32} />;
    case 'postgresql':
      return <PostgreSQL dimensionPx={size || 32} />;
    case 'taylor':
      return <Taylor dimensionPx={size || 32} />;
    case 'sunshine':
      return <Sunshine dimensionPx={size || 32} />;
    case 'kv':
      return <KeyValueStore dimensionPx={size || 32} />;
    case 'icon-webhook':
      return (
        <LogoContainer className={cn(styles.webhook)} style={{ width: size, height: size }}>
          <WebhookIcon color="white" />
        </LogoContainer>
      );
    case 'strada.translate':
      return (
        <LogoContainer className={cn(styles.globe)} style={{ width: size, height: size }}>
          <GlobeIcon color="white" />
        </LogoContainer>
      );
    case 'strada.remove-pii':
      return (
        <LogoContainer className={cn(styles.strikethrough)} style={{ width: size, height: size }}>
          <StrikethroughIcon color="white" />
        </LogoContainer>
      );
    case 'strada.continue-if':
      return (
        <LogoContainer className={cn(styles.filter)} style={{ width: size, height: size }}>
          <FilterIcon color="white" />
        </LogoContainer>
      );
    case 'icon-play':
      return (
        <LogoContainer className={cn(styles.play)} style={{ width: size, height: size }}>
          <PlayIcon color="white" />
        </LogoContainer>
      );
    default:
      // TODO: this should be a notification/event and not console error
      // However, given that this shouldn't happen, we will keep this error.
      console.error(`AppLogo: No SVG found for slug: ${slug}`);
      return (
        <Image
          src={`/images/${slug}.png`}
          width={size || 32}
          height={size || 32}
          alt={slug}
          className={cn('mr-2', className)}
        />
      );
  }
}
