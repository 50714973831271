import { useEffect, useState } from 'react';
import { Input } from '@/app/components/ui/input';
import { cn, isUrlValid } from '@/utils';
import inputStyles from '@/app/components/ui/input-field.styles.module.css';
import providerStyles from './providers/providers.module.css';

type OverrideUrlProps = {
  defaultValue: string;
  onChange: (url: string) => void;
  onError: (error: string | null) => void;
  placeholder?: string;
  label?: string;
  required?: boolean;
};

export default function OverrideUrl({
  defaultValue = '',
  onChange,
  onError,
  placeholder,
  label,
  required = false,
}: OverrideUrlProps) {
  const [overrideUrl, setOverrideUrl] = useState(defaultValue);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!overrideUrl) {
      setError('');
      return;
    } else {
      if (!isUrlValid(overrideUrl)) {
        setError('Invalid URL');
      } else {
        setError('');
      }
    }
  }, [overrideUrl]);

  useEffect(() => {
    if (error) {
      onError(error);
    } else {
      onError(null);
    }
  }, [error]);

  // Lift state up
  useEffect(() => onChange(overrideUrl), [overrideUrl]);

  return (
    <div className={providerStyles.frame}>
      <label htmlFor="slug" className="block font-detail font-medium leading-6 text-gray-900">
        {label || 'API Base URL'} <span className="text-red-700">{required ? '*' : ''}</span>
      </label>
      <Input
        onChange={(e) => setOverrideUrl(e.target.value)}
        value={overrideUrl}
        placeholder={placeholder || 'https://sandbox.example.com/api'}
        className={cn(inputStyles.inputField, 'font-detail')}
        data-1p-ignore
      />
      <p
        className={cn('text-red-700 font-detail', {
          hidden: !error,
        })}
      >
        {error}
      </p>
    </div>
  );
}
