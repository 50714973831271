// @ts-strict-ignore
// Encode email address and convert to base64 string so it can be used
// as a query parameter in the URL
function encodeEmail(email: string): string {
  return btoa(email);
}

// Decode email address from base64 string
function decodeEmail(encodedEmail: string): string {
  try {
    return atob(encodedEmail);
  } catch (error) {
    return null;
  }
}

// Function for generating the query parameter
export function generateQueryParameter(email: string): string {
  return `?ea=${encodeEmail(email)}`;
}

// Function for parsing the query parameter
export function parseQueryParameter(queryParameter: string): string {
  return decodeEmail(queryParameter.replace('?ea=', ''));
}
