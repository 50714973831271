import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getIncrementedName(name: string, otherNames: string[], startingIndex: number): string {
  let proposedName = `${name}${startingIndex}`;
  while (otherNames.find((n) => n === proposedName)) {
    startingIndex++;
    proposedName = `${name}${startingIndex}`;
  }

  return proposedName;
}

export const isUrlValid = (url) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

export function groupBy<T, K extends keyof any>(list: T[], getKey: (item: T) => K): Record<K, T[]> {
  return list.reduce((previous, currentItem) => {
    const key = getKey(currentItem);
    if (!previous[key]) {
      previous[key] = [];
    }
    previous[key].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);
}
