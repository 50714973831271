import { z } from 'zod';

// Update Zod schema for form validation with the specified fields
export const DbConnectionSchema = z.object({
  connectionName: z.string().min(1, 'Connection name is required'),
  host: z.string().min(1, 'Host is required'),
  database: z.string().min(1, 'Database is required'),
  port: z.coerce.number().min(1, 'Port is required').max(65535, 'Port must be between 1 and 65535'),
  user: z.string().min(1, 'User is required'),
  password: z.string().min(1, 'Password is required'),
});

export type DBConnectionFormData = z.infer<typeof DbConnectionSchema>;

export const BasicAuthConnectionSchema = z.object({
  connectionName: z.string().min(1, 'Connection name is required'),
  username: z.string().min(1, 'Username is required'),
  password: z.string().min(1, 'Password is required'),
});

export type BasicAuthFormData = z.infer<typeof BasicAuthConnectionSchema>;
