// @ts-strict-ignore
'use client';

import { useEffect, useState } from 'react';
import * as CronParser from 'cron-parser';
import { DateTime } from 'luxon';

export default function CronNextExecutions({
  expression,
  onChange,
  onError,
}: {
  expression: string;
  onChange?: (value: any) => void;
  onError?: (value: boolean) => void;
}) {
  // This component takes a cron expression and displays the next 3 executions

  const [nextExecutions, setNextExecutions] = useState<any[]>([]);

  useEffect(() => {
    try {
      if (!expression) return;
      const interval = CronParser.parseExpression(expression);
      // Set next 3 executions
      const nextExecutions = [];
      for (let i = 0; i < 3; i++) {
        nextExecutions.push(interval.next().toDate());
      }
      setNextExecutions(nextExecutions);

      // Pass next executions to parent component
      if (onChange) onChange(nextExecutions);
    } catch (error) {
      if (onError) onError(error);
    }
  }, [expression]);

  return (
    <div>
      <p className="font-detail text-slate-500">Next executions:</p>
      {nextExecutions.length > 0 &&
        nextExecutions.map((execution, index) => (
          <p key={index} className="font-detail text-slate-500">
            {execution.toLocaleString() + ' ' + DateTime.now().offsetNameShort}
          </p>
        ))}
    </div>
  );
}
