// @ts-strict-ignore
import React, { useCallback, useContext } from 'react';
import ConnectionForm from '../connections/connection-form';
import { DialogContent } from '../ui/dialog';
import { cn } from '@/utils';
import styles from './new-connection.module.css';
import AppLogo from '../logos/app-logo';
import { useAppDispatch, useAppSelector } from '@/lib/redux/hooks';
import { setSelectedWorkflowFunctionId } from '@/app/ide/sliceIde';
import { updateWorkflowFunctionConnection } from '@/app/ide/sliceIde';

interface NewConnectionDialogProps {
  onClose: () => void;
}

const NewConnectionDialog = ({ onClose }: NewConnectionDialogProps) => {
  const dispatch = useAppDispatch();
  const workflowsPageState = useAppSelector((state) => state.workflowsPage);
  const app = workflowsPageState.newConnectionTriggerData.app;

  const onNewConnectionComplete = useCallback(
    async (connection) => {
      dispatch(
        updateWorkflowFunctionConnection({
          id: workflowsPageState.newConnectionTriggerData.id,
          connection,
        })
      );

      // Update the selected workflow function
      dispatch(setSelectedWorkflowFunctionId(workflowsPageState.newConnectionTriggerData.id));

      onClose();
    },
    [workflowsPageState.newConnectionTriggerData]
  );

  return (
    <DialogContent
      className={cn(styles.container)}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onInteractOutside={(event) => event.preventDefault()}
    >
      <div className={styles.frame}>
        <div className={styles.headerWrapper}>
          <AppLogo className="mr-0" slug={app.slug} size={32} />
          <h2 className={cn(styles.headerContent, 'font-large')}>{app.name}</h2>
        </div>
        <ConnectionForm app={app} onComplete={onNewConnectionComplete} onClose={onClose} />
      </div>
    </DialogContent>
  );
};

export default NewConnectionDialog;
