// @ts-strict-ignore
import { useQuery } from '@tanstack/react-query';
import request from '@/lib/clients/request';
import { connectionsKeys } from './keys';

export const useGenerateConnectionAlias = (appSlug?: string) => {
  return useQuery({
    queryKey: connectionsKeys.connectionAlias(appSlug),
    queryFn: async () => {
      const res = await request.get<any, any>(`/api/connections/alias/${appSlug}`);
      return res.data;
    },
    enabled: !!appSlug,
  });
};
