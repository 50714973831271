export const ACTION_NAME_MAP = {
  'strada.translate': 'Translate',
  'strada.remove-pii': 'Remove PII',
  'strada.continue-if': 'Filter (Continue if)',
  'intercom.add-private-note': 'Add note to conversation',
};

export const APP_EVENT_NAME_MAP = {
  intercom: {
    'intercom.conversation.user.created': 'New conversation created',
    'intercom.conversation.user.replied': 'Conversation replied',
  },
};

export const APP_PLACEHOLDER_DATA = {
  intercom: {
    type: 'conversation',
    id: '1295',
    title: 'Conversation Title',
    created_at: 1663597223,
    updated_at: 1663597260,
    waiting_since: 1663597260,
    snoozed_until: 1663597260,
    open: true,
    state: 'open',
    read: true,
    priority: 'priority',
    admin_assignee_id: 0,
    team_assignee_id: '5017691',
    tags: {
      type: 'tag.list',
      tags: [
        {
          type: 'tag',
          id: '123456',
          name: 'Test tag',
          applied_at: 1663597223,
          applied_by: {
            type: 'contact',
            id: '1a2b3c',
          },
        },
      ],
    },
    conversation_rating: {
      rating: 5,
      remark: '',
      created_at: 1671028894,
      contact: {
        type: 'contact',
        id: '5ba682d23d7cf92bef87bfd4',
        external_id: 'f3b87a2e09d514c6c2e79b9a',
      },
      teammate: {
        type: 'contact',
        id: '1a2b3c',
      },
    },
    source: {
      type: 'conversation',
      id: '3',
      delivered_as: 'operator_initiated',
      subject: '',
      body: '<p>Hey there!</p>',
      author: {
        type: 'admin',
        id: '274',
        name: 'Operator',
        email: 'operator+abcd1234@intercom.io',
      },
      attachments: [
        {
          type: 'upload',
          name: 'example.png',
          url: 'https://picsum.photos/200/300',
          content_type: 'image/png',
          filesize: 100,
          width: 100,
          height: 100,
        },
      ],
      url: null,
      redacted: false,
    },
    contacts: {
      type: 'contact.list',
      contacts: [
        {
          type: 'contact',
          id: '5ba682d23d7cf92bef87bfd4',
          external_id: 'f3b87a2e09d514c6c2e79b9a',
        },
      ],
    },
    teammates: {
      type: 'admin.list',
      teammates: [
        {
          type: 'contact',
          id: '1a2b3c',
        },
      ],
    },
    custom_attributes: {
      property1: 'string',
      property2: 'string',
    },
    first_contact_reply: {
      created_at: 1663597223,
      type: 'conversation',
      url: 'https://developers.intercom.com/',
    },
    sla_applied: {
      type: 'conversation_sla_summary',
      sla_name: '',
      sla_status: 'hit',
    },
    statistics: {
      type: 'conversation_statistics',
      time_to_assignment: 2310,
      time_to_admin_reply: 2310,
      time_to_first_close: 2310,
      time_to_last_close: 2310,
      median_time_to_reply: 2310,
      first_contact_reply_at: 1663597233,
      first_assignment_at: 1663597233,
      first_admin_reply_at: 1663597233,
      first_close_at: 1663597233,
      last_assignment_at: 1663597233,
      last_assignment_admin_reply_at: 1663597233,
      last_contact_reply_at: 1663597233,
      last_admin_reply_at: 1663597233,
      last_close_at: 1663597233,
      last_closed_by_id: 'c3po',
      count_reopens: 1,
      count_assignments: 1,
      count_conversation_parts: 1,
    },
    conversation_parts: {
      type: 'conversation_part.list',
      conversation_parts: [
        {
          type: 'conversation_part',
          id: '3',
          part_type: 'comment',
          body: '<p>Okay!</p>',
          created_at: 1663597223,
          updated_at: 1663597260,
          notified_at: 1663597260,
          assigned_to: {
            type: 'contact',
            id: '1a2b3c',
          },
          author: {
            type: 'admin',
            id: '274',
            name: 'Operator',
            email: 'operator+abcd1234@intercom.io',
          },
          attachments: [
            {
              type: 'upload',
              name: 'example.png',
              url: 'https://picsum.photos/200/300',
              content_type: 'image/png',
              filesize: 100,
              width: 100,
              height: 100,
            },
          ],
          external_id: 'abcd1234',
          redacted: false,
        },
      ],
      total_count: 2,
    },
    linked_objects: {
      type: 'list',
      total_count: 100,
      has_more: false,
      data: [
        {
          type: 'ticket',
          id: '7583',
          category: 'Customer',
        },
      ],
    },
  },
} as const;

export const ACTION_PLACEHOLDER_DATA = {
  'strada.translate': {
    translated: '...will populate after executing this step.',
  },
  'strada.remove-pii': {
    redacted: '...will populate after executing this step.',
  },
  'strada.continue-if': {},
  'intercom.add-private-note': {
    type: 'note',
    id: '444',
    created_at: 1712222918,
    contact: {
      type: 'contact',
      id: '660e72c66abd01bd27af614f',
    },
    author: {
      type: 'admin',
      id: '991282384',
      name: 'Ciaran103 Lee',
      email: 'admin103@email.com',
      away_mode_enabled: false,
      away_mode_reassign: false,
    },
    body: '<p>Hello</p>',
  },
};
