'use client';

import { datadogLogs } from '@datadog/browser-logs';
import { useEffect } from 'react';
import config from '@/config';

export function Initialization() {
  useEffect(() => {
    datadogLogs.init({
      clientToken: config.DATADOG_TOKEN,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
    });
  }, []);

  return undefined;
}
