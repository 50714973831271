// @ts-strict-ignore
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { WorkflowStateType, CodeDependencyGraph } from '@/context/types/workflow';

export const initialState: WorkflowStateType = {
  workflowId: '',
  workflowTypeId: '',
  workflowProjectId: '',
  workflowTriggerInfo: {
    type: undefined,
    schedule: '',
    webhookUrl: '',
  },
  initialSnippet: '',
  // utility state
  codeAnalysis: { actions_metadata: [] },
  boilerplateCode: { code: '', numLines: 0 },
  isPayloadModalOpen: false,
  debugger: 'Press ▷ Test to see debug logs here',
  httpLogs: [],
  currentVersionId: '',
  undeployedChanges: false,
  filesystem: [],
};

const WORKFLOW_SLICE_NAME = 'workflow';

export const sliceWorkflow = createSlice({
  name: WORKFLOW_SLICE_NAME,
  initialState,
  reducers: {
    setInitialSnippet: (state, action: PayloadAction<any>) => {
      state.initialSnippet = action.payload;
    },
    setCodeAnalysisActionResults: (state, action: PayloadAction<any>) => {
      state.codeAnalysis.actions_metadata = action.payload;
    },
    actionDependencyGraphUpdated: (state, action: PayloadAction<CodeDependencyGraph>) => {
      state.codeAnalysis.action_dependency_graph = action.payload;
    },
    setBoilerplateCode: (state, action: PayloadAction<any>) => {
      state.boilerplateCode = action.payload;
    },
    setWorkflowId: (state, action: PayloadAction<any>) => {
      state.workflowId = action.payload;
    },
    setWorkflowTypeId: (state, action: PayloadAction<any>) => {
      state.workflowTypeId = action.payload;
    },
    setWorkflowProjectId: (state, action: PayloadAction<any>) => {
      state.workflowProjectId = action.payload;
    },
    setWorkflowTriggerInfo: (state, action: PayloadAction<any>) => {
      state.workflowTriggerInfo = action.payload;
    },
    initDebuggerArray: (state) => {
      state.debugger = [];
    },
    appendDebuggerArray: (state, action: PayloadAction<any>) => {
      state.debugger = [...state.debugger, action.payload];
    },
    debuggerRunning: (state) => {
      state.debugger = 'Running...';
    },
    initDebugger: (state) => {
      state.debugger = initialState.debugger;
    },
    setCurrentVersionId: (state, action: PayloadAction<any>) => {
      state.currentVersionId = action.payload;
    },
    setUndeployedChanges: (state, action: PayloadAction<any>) => {
      state.undeployedChanges = action.payload;
    },
    clearWorkflowState: () => {
      return { ...initialState };
    },
  },
});

// Action creators are generated for each case reducer function
export const { name: SLICE_WORKFLOW_NAME } = sliceWorkflow;
export const {
  setInitialSnippet,
  setCodeAnalysisActionResults,
  actionDependencyGraphUpdated,
  setBoilerplateCode,
  setWorkflowId,
  setWorkflowTypeId,
  setWorkflowProjectId,
  setWorkflowTriggerInfo,
  initDebuggerArray,
  appendDebuggerArray,
  debuggerRunning,
  initDebugger,
  setCurrentVersionId,
  setUndeployedChanges,
  clearWorkflowState,
} = sliceWorkflow.actions;
export default sliceWorkflow.reducer;
