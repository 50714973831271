import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import ideReducer, { SLICE_IDE_NAME } from '@/app/ide/sliceIde';
import workflowsPageReducer, { SLICE_WORKFLOWS_PAGE_NAME } from '@/app/workflows/sliceWorkflowsPage';
import workflowReducer, { SLICE_WORKFLOW_NAME } from '@/app/workflows/sliceWorkflow';
import noCodeBuilderReducer, { SLICE_NO_CODE_BUILDER_NAME } from '@/app/no-code/sliceNoCodeBuilder';

export const makeStore = () => {
  const serializableMiddlewareOptions = {
    ignoredActions: [
      // TODO: remove openWorkflowModal redux action
      `${SLICE_WORKFLOWS_PAGE_NAME}/openWorkflowModal`,
    ],
    ignoredPaths: [
      // TODO: remove onWorkflowCreated and onCloseModalRedirect form redux state
      `${SLICE_WORKFLOWS_PAGE_NAME}.onWorkflowCreated`,
      `${SLICE_WORKFLOWS_PAGE_NAME}.onCloseModalRedirect`,
    ],
  };
  const listenerMiddleware = createListenerMiddleware();

  return configureStore({
    reducer: {
      [SLICE_NO_CODE_BUILDER_NAME]: noCodeBuilderReducer,
      [SLICE_IDE_NAME]: ideReducer,
      [SLICE_WORKFLOWS_PAGE_NAME]: workflowsPageReducer,
      [SLICE_WORKFLOW_NAME]: workflowReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: serializableMiddlewareOptions,
      }).prepend(listenerMiddleware.middleware),
  });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
