// @ts-strict-ignore
import {
  ConnectionWithApp,
  WorkflowFunctionWithApp,
  WorkflowFunctionWithAppConnection,
} from '@/context/types/workflows-page';
import { WorkflowFunction, getAppFromWorkflowFunction } from '@/models/workflow-config';
import { ActionConfig } from '@strada/db';
import { v4 as uuidv4 } from 'uuid';
import { getIncrementedName } from '..';

type NewActionHandler = {
  newAction: WorkflowFunctionWithAppConnection;
  workflowFunctionsArray: WorkflowFunction[];
  actionConfig: ActionConfig;
  updateAppToConnection?: boolean;
};

export const newActionHandler = ({
  newAction,
  workflowFunctionsArray,
  actionConfig,
  updateAppToConnection = false,
}: NewActionHandler) => {
  // If the action has a default slug, set the title to that
  if (actionConfig?.defaultSlug) {
    newAction.title = actionConfig.defaultSlug;
  }

  // If there are duplicate names, increment the name
  const duplicateNames = workflowFunctionsArray
    .filter((action) => action.id !== newAction.id)
    .filter((action) => action.title === newAction.title);

  // Only check for duplicates when the action is created and not when it is being updated
  if (duplicateNames.length > 0 && !updateAppToConnection) {
    newAction.title = getIncrementedName(
      newAction.title,
      workflowFunctionsArray.map((param: WorkflowFunction) => param.title),
      duplicateNames.length
    );
  }

  return {
    updatedWorkflowFunctionsArray: workflowFunctionsArray,
    newAction,
  };
};

export function replaceConnectionForCurrentWorkflowFunction(
  newConnection: ConnectionWithApp,
  currentWorkflowFunction: WorkflowFunctionWithAppConnection,
  actionConfig?: ActionConfig
) {
  const currentApp = getAppFromWorkflowFunction(currentWorkflowFunction as WorkflowFunction);

  return {
    ...currentWorkflowFunction,
    connection: newConnection,
    ...(actionConfig && {
      app: newConnection.app,

      // If the connection is different from the current app, reset the action type,
      // input section form data, input section schema, endpoint, and action properties
      ...(newConnection?.app.slug !== currentApp.slug && {
        actionType: actionConfig,
        inputSectionFormData: {},
        inputSectionSchema: {},
        endpoint: {},
        actionProperties: {},
      }),
    }),
  };
}

export function newWorkflowFunctionFromConnection(connection, actionConfig: ActionConfig): WorkflowFunction {
  return {
    id: uuidv4(),
    connection,
    functionParameters: [],
    actionType: actionConfig,
    inputSectionFormData: {},
    inputSectionSchema: {},
    endpoint: {},
    actionProperties: {},
    title: connection.app.name,
  };
}

export function replaceAppForCurrentWorkflowFunction(
  app,
  workflowFunction: WorkflowFunctionWithApp,
  workflowFunctionsArray: WorkflowFunction[],
  actionConfig?: ActionConfig
): WorkflowFunctionWithAppConnection {
  const { newAction } = newActionHandler({
    newAction: { ...workflowFunction },
    workflowFunctionsArray,
    actionConfig,
    updateAppToConnection: true,
  });

  return {
    ...workflowFunction,
    app,
    connection: null,
    ...(actionConfig && {
      actionType: actionConfig,
      inputSectionFormData: {},
      inputSectionSchema: {},
      functionParameters: [],
      endpoint: {},
      actionProperties: {},
      title: newAction.title,
    }),
  };
}

// TODO: this function is flawed as the DB model for workflow function does not have
// an app column, it only has a connection column or action type column.
// This needs to be refactored, however, if we don't append an action config, then we can't
export function newWorkflowFunctionFromApp(app, actionConfig: ActionConfig): WorkflowFunction {
  return {
    id: uuidv4(),
    connection: null,
    app,
    functionParameters: [],
    actionType: actionConfig,
    inputSectionFormData: {},
    inputSectionSchema: {},
    endpoint: {},
    actionProperties: {},
    title: app.name,
  };
}
