// @ts-strict-ignore
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Workflow } from '@strada/db';
import { AiChat } from '@/app/ide/aiAgent/types';
import { WorkflowFunction } from '@/models/workflow-config';
import { ConsoleTabOptions, DivideOrientation, SaveWorkflowBody } from '@/app/ide/types';
import { EMPTY_CODE_STATE } from '@/app/ide/constants';
import { createAppAsyncThunk } from '@/lib/redux/utils';
import { EditorView } from 'codemirror';
import { setCurrentVersionId, setUndeployedChanges } from '../workflows/sliceWorkflow';

export interface IdeState {
  // TODO: remove when workflow creation takes in workflow actions
  _loadingInitalIdePage?: boolean;
  initialVersionId?: string;
  mainCodeBlock: string;
  // TODO: make payload questionable
  payload: Record<string, unknown>;
  payloadModalOpen: boolean;
  workflowFunctions: Record<string, WorkflowFunction>;
  workflow: Workflow | null;
  selectedWorkflowFunctionId: string;
  userAIPrompt: string;
  aiChat?: AiChat;
  aiAssistantCurrentMessage?: string;
  consoleSelectedTab: ConsoleTabOptions;
  divideOrientation: DivideOrientation;
  httpFormFieldsMeta: Record<
    string,
    {
      isRequired: boolean;
      schemaType?: string;
      isSelected: boolean;
      isReadOnly: boolean;
      description?: string;
    }
  >;
}

const initialState: IdeState = {
  workflowFunctions: {},
  payload: {},
  payloadModalOpen: false,
  workflow: null,
  selectedWorkflowFunctionId: null,
  consoleSelectedTab: 'logs',
  divideOrientation: 'vertical',
  userAIPrompt: '',
  httpFormFieldsMeta: {},
  mainCodeBlock: EMPTY_CODE_STATE,
};

const IDE_SLICE_NAME = 'ide';

export const updateWorkflowFunctionTitleThunk = createAppAsyncThunk<
  { id: string; title: string; newCodeText?: string },
  { id: string; title: string; viewEditor: EditorView | null }
>(`${IDE_SLICE_NAME}/updateWorkflowFunctionTitle`, ({ id, title, viewEditor }, { getState }) => {
  const state = getState();
  let newCodeText: string;
  if (viewEditor) {
    const { title: oldTitle } = Object.values(state[IDE_SLICE_NAME].workflowFunctions).find(
      (wrkFlo) => wrkFlo.id === id
    );
    const doc = viewEditor.state.doc;
    newCodeText = doc.toString().replaceAll(`${oldTitle}.`, `${title}.`);

    const transaction = viewEditor.state.update({
      changes: { from: 0, to: doc.length, insert: newCodeText },
    });
    viewEditor.dispatch(transaction);
  }
  return { id, title, newCodeText };
});

export const replaceMainCodeThunk = createAppAsyncThunk<
  { newCodeText: string },
  { newCodeText: string; viewEditor: EditorView | null }
>(`${IDE_SLICE_NAME}/replaceMainCode`, ({ newCodeText, viewEditor }) => {
  if (viewEditor) {
    const doc = viewEditor.state.doc;

    const transaction = viewEditor.state.update({
      changes: { from: 0, to: doc.length, insert: newCodeText },
    });
    viewEditor.dispatch(transaction);
  }
  return { newCodeText };
});

export const saveWorkflowThunk = createAppAsyncThunk<
  any,
  {
    workflowId: string;
    saveFunction: (arg: SaveWorkflowBody) => Promise<any>;
  }
>(`${IDE_SLICE_NAME}/saveWorkflow`, async ({ workflowId, saveFunction }, { dispatch, getState }) => {
  const state = getState();
  if (state.ide.initialVersionId) {
    const response = await saveFunction({
      workflowTypeId: workflowId,
      code: state.ide.mainCodeBlock,
      workflowFunctions: state.ide.workflowFunctions,
      payload: state.ide.payload,
    });
    dispatch(setUndeployedChanges(!response.isCurrentVersionDeployed));
    dispatch(setCurrentVersionId(response.currentVersionId));
    return response;
  }
  return undefined;
});

export const sliceIde = createSlice({
  name: IDE_SLICE_NAME,
  initialState,
  reducers: {
    pageMounted: (
      state,
      action: PayloadAction<{
        initialVersionId: string;
        code: string;
        payload?: Record<string, unknown>;
        workflowFunctions: WorkflowFunction[];
      }>
    ) => {
      state.mainCodeBlock = action.payload.code;

      state.payload = action.payload.payload;

      // Add workflow functions
      state.workflowFunctions = Object.fromEntries(
        action.payload.workflowFunctions.map((ac: WorkflowFunction) => [ac.id, ac])
      );

      // Initial version of workflow
      state.initialVersionId = action.payload.initialVersionId;
    },
    mainCodeBlockChanged: (state, action: PayloadAction<string>) => {
      state.mainCodeBlock = action.payload;
    },
    setWorkflow: (state, action: PayloadAction<Workflow>) => {
      state.workflow = action.payload;
    },
    setSelectedWorkflowFunctionId: (state, action: PayloadAction<string>) => {
      state.selectedWorkflowFunctionId = action.payload;
    },
    payloadSelected: (state, action: PayloadAction<Record<string, unknown>>) => {
      state.payload = action.payload;
    },
    // AI Actions Start
    userAIPromptChanged: (state, action: PayloadAction<string>) => {
      state.userAIPrompt = action.payload;
    },
    userPromptedAi: (state, action: PayloadAction<AiChat>) => {
      state.aiChat = action.payload;
    },
    aiResponseStreamFinished: (state) => {
      const aiAssistantCurrentMessage = state.aiAssistantCurrentMessage;
      if (aiAssistantCurrentMessage) {
        state.aiChat = [...state.aiChat, { role: 'assistant', content: aiAssistantCurrentMessage }];
      }
      state.aiAssistantCurrentMessage = undefined;
    },
    aiResponseChunkReceived: (state, action: PayloadAction<string>) => {
      state.aiAssistantCurrentMessage = state.aiAssistantCurrentMessage
        ? state.aiAssistantCurrentMessage + action.payload
        : action.payload;
    },
    askedAIForDebuggingHelp: (state, action: PayloadAction<string>) => {
      const prompt = `I ran into the following exception while executing the code: \n \`\`\`\n${action.payload}\n\`\`\``;
      state.userAIPrompt = prompt;
      state.consoleSelectedTab = 'ai-agent';
    },
    // AI Actions End
    setConsoleSelectedTab: (state, action: PayloadAction<ConsoleTabOptions>) => {
      state.consoleSelectedTab = action.payload;
    },
    setDivideOrientation: (state, action: PayloadAction<DivideOrientation>) => {
      state.divideOrientation = action.payload;
    },
    payloadModalToggled: (state, action: PayloadAction<boolean>) => {
      state.payloadModalOpen = action.payload;
    },

    // RJSF related
    fieldMounted: (
      state,
      action: PayloadAction<{
        fieldId: string;
        isRequired: boolean;
        hasFormData: boolean;
        isReadOnly?: boolean;
        schemaType?: string;
        description?: string;
      }>
    ) => {
      state.httpFormFieldsMeta[action.payload.fieldId] = {
        isRequired: action.payload.isRequired,
        schemaType: action.payload.schemaType,
        isSelected: action.payload.hasFormData,
        isReadOnly: action.payload.isReadOnly,
        description: action.payload.description,
      };
    },
    fieldUnMounted: (state, action: PayloadAction<{ fieldId: string }>) => {
      delete state.httpFormFieldsMeta[action.payload.fieldId];
    },
    fieldSelected: (state, action: PayloadAction<{ fieldId: string }>) => {
      state.httpFormFieldsMeta[action.payload.fieldId].isSelected = true;
    },
    fieldUnSelected: (state, action: PayloadAction<{ fieldId: string }>) => {
      state.httpFormFieldsMeta[action.payload.fieldId].isSelected = false;
    },
    // RJSF related End
    pageUnMounted: () => {
      return { ...initialState };
    },

    // Tech Debt WorkflowFnction actions
    setInitialWorkflowFunctions: (state, action: PayloadAction<any>) => {
      const newRecord: Record<string, WorkflowFunction> = Object.fromEntries(
        action.payload.map((ac: WorkflowFunction) => [ac.id, ac])
      );

      state.workflowFunctions = newRecord;
    },
    updateWorkflowFunctions: (state, action: PayloadAction<any[]>) => {
      const newRecord: Record<string, WorkflowFunction> = Object.fromEntries(
        action.payload.map((ac: WorkflowFunction) => [ac.id, ac])
      );

      state.workflowFunctions = newRecord;
    },
    updateWorkflowFunctionConnection: (state, action: PayloadAction<any>) => {
      state.workflowFunctions = Object.fromEntries(
        Object.values(state.workflowFunctions).map((ac: WorkflowFunction) => [
          ac.id,
          ac.id === action.payload.id ? { ...ac, connection: action.payload.connection } : ac,
        ])
      );
    },
    removeWorkflowFunction: (state, action: PayloadAction<any>) => {
      state.workflowFunctions = Object.fromEntries(
        Object.values(state.workflowFunctions)
          .filter((ac: WorkflowFunction) => ac.id !== action.payload)
          .map((ac: WorkflowFunction) => [ac.id, ac])
      );
    },
    addFunctionParameter: (state, action: PayloadAction<any>) => {
      state.workflowFunctions = Object.fromEntries(
        Object.entries(state.workflowFunctions).map(([key, value]) => {
          if (key === action.payload.functionId) {
            return [
              key,
              {
                ...value,
                functionParameters: [...value.functionParameters, action.payload.functionParameter],
              },
            ];
          } else {
            return [key, value];
          }
        })
      );
    },
    updateFunctionParameter: (state, action: PayloadAction<any>) => {
      state.workflowFunctions = Object.fromEntries(
        Object.entries(state.workflowFunctions).map(([key, value]) => {
          if (key === action.payload.functionId) {
            const functionParameter = action.payload.functionParameter;
            const functionParameterId = functionParameter.id;

            return [
              key,
              {
                ...value,
                functionParameters: value.functionParameters.map((fp) =>
                  fp.id === functionParameterId ? functionParameter : fp
                ),
              },
            ];
          } else {
            return [key, value];
          }
        })
      );
    },
    removeFunctionParameter: (state, action: PayloadAction<any>) => {
      state.workflowFunctions = Object.fromEntries(
        Object.entries(state.workflowFunctions).map(([swFunctionId, swFunction]) => {
          if (swFunctionId === action.payload.functionId) {
            return [
              swFunctionId,
              {
                ...swFunction,
                functionParameters: swFunction.functionParameters.filter(
                  (currentFunctionParameter) =>
                    currentFunctionParameter.id !== action.payload.functionParameterId
                ),
              },
            ];
          } else {
            return [swFunctionId, swFunction];
          }
        })
      );
    },
    changeWorkflowFunctionActionType: (state, action: PayloadAction<any>) => {
      state.workflowFunctions = Object.fromEntries(
        Object.entries(state.workflowFunctions).map(([key, value]) => {
          if (key === action.payload.functionId) {
            return [
              key,
              {
                ...value,
                actionType: action.payload.actionType,
                endpoint: {},
                inputSectionFormData: {}, // Also reset the form data if any
                inputSectionSchema: {}, // Also reset the schema if any
              },
            ];
          } else {
            return [key, value];
          }
        })
      );
    },
    setDefaultWorkflowFunctionActionType: (state, action: PayloadAction<any>) => {
      state.workflowFunctions = Object.fromEntries(
        Object.entries(state.workflowFunctions).map(([key, value]) => {
          if (key === action.payload.functionId) {
            return [
              key,
              {
                ...value,
                actionType: action.payload.actionType,
              },
            ];
          } else {
            return [key, value];
          }
        })
      );
    },
    setFunctionEndpoint: (state, action: PayloadAction<any>) => {
      state.workflowFunctions = Object.fromEntries(
        Object.entries(state.workflowFunctions).map(([key, value]) => {
          if (key === action.payload.functionId) {
            return [
              key,
              {
                ...value,
                endpoint: action.payload.endpoint,
                inputSectionFormData: {}, // Also reset the form data if any
                inputSectionSchema: {}, // Also reset the schema if any
              },
            ];
          } else {
            return [key, value];
          }
        })
      );
    },
    setFunctionEndpointOnly: (state, action: PayloadAction<any>) => {
      state.workflowFunctions = Object.fromEntries(
        Object.entries(state.workflowFunctions).map(([key, value]) => {
          if (key === action.payload.functionId) {
            return [
              key,
              {
                ...value,
                endpoint: action.payload.endpoint,
              },
            ];
          } else {
            return [key, value];
          }
        })
      );
    },
    setFunctionInputSectionFormData: (state, action: PayloadAction<any>) => {
      state.workflowFunctions = Object.fromEntries(
        Object.entries(state.workflowFunctions).map(([key, value]) => {
          if (key === action.payload.functionId) {
            return [
              key,
              {
                ...value,
                inputSectionFormData: action.payload.inputSectionFormData,
              },
            ];
          } else {
            return [key, value];
          }
        })
      );
    },
    setFunctionInputSectionSchema: (state, action: PayloadAction<any>) => {
      state.workflowFunctions = Object.fromEntries(
        Object.entries(state.workflowFunctions).map(([key, value]) => {
          if (key === action.payload.functionId) {
            return [
              key,
              {
                ...value,
                inputSectionSchema: action.payload.inputSectionSchema,
              },
            ];
          } else {
            return [key, value];
          }
        })
      );
    },
    setFunctionInputSectionFormDataItem: (state, action: PayloadAction<any>) => {
      state.workflowFunctions = Object.fromEntries(
        Object.entries(state.workflowFunctions).map(([key, value]) => {
          if (key === action.payload.functionId) {
            return [
              key,
              {
                ...value,
                inputSectionFormData: {
                  ...value.inputSectionFormData,
                  [action.payload.sectionName]: action.payload.formData,
                },
              },
            ];
          } else {
            return [key, value];
          }
        })
      );
    },
    setFunctionInputSectionFormDataSubitem: (state, action: PayloadAction<any>) => {
      state.workflowFunctions = Object.fromEntries(
        Object.entries(state.workflowFunctions).map(([key, value]) => {
          if (key === action.payload.functionId) {
            return [
              key,
              {
                ...value,
                inputSectionFormData: {
                  ...value.inputSectionFormData,
                  [action.payload.sectionName]: {
                    ...value.inputSectionFormData[action.payload.sectionName],
                    [action.payload.subSectionName]: action.payload.formData,
                  },
                },
              },
            ];
          } else {
            return [key, value];
          }
        })
      );
    },
    setFunctionInputSectionFormDataSubSubitem: (state, action: PayloadAction<any>) => {
      state.workflowFunctions = Object.fromEntries(
        Object.entries(state.workflowFunctions).map(([key, value]) => {
          if (key === action.payload.functionId) {
            return [
              key,
              {
                ...value,
                inputSectionFormData: {
                  ...value.inputSectionFormData,
                  [action.payload.sectionName]: {
                    ...value.inputSectionFormData[action.payload.sectionName],
                    [action.payload.subSectionName]: {
                      ...value.inputSectionFormData[action.payload.sectionName][
                        action.payload.subSectionName
                      ],
                      [action.payload.subSubSectionName]: action.payload.formData,
                    },
                  },
                },
              },
            ];
          } else {
            return [key, value];
          }
        })
      );
    },

    // Tech Debt to suppoer worflow creation
    setIdePageLoading: (state, action: PayloadAction<boolean>) => {
      state._loadingInitalIdePage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateWorkflowFunctionTitleThunk.fulfilled, (state, action) => {
        if (action.payload.newCodeText !== undefined) {
          state.mainCodeBlock = action.payload.newCodeText;
        }

        state.workflowFunctions = Object.fromEntries(
          Object.values(state.workflowFunctions).map((ac: WorkflowFunction) => [
            ac.id,
            ac.id === action.payload.id ? { ...ac, title: action.payload.title } : ac,
          ])
        );
      })
      .addCase(replaceMainCodeThunk.fulfilled, (state, action) => {
        state.mainCodeBlock = action.payload.newCodeText;
      });
  },
});

// Action creators are generated for each case reducer function
export const { name: SLICE_IDE_NAME } = sliceIde;
export const {
  pageMounted,
  mainCodeBlockChanged,
  setWorkflow,
  setSelectedWorkflowFunctionId,
  payloadSelected,
  userAIPromptChanged,
  userPromptedAi,
  aiResponseStreamFinished,
  aiResponseChunkReceived,
  setConsoleSelectedTab,
  setDivideOrientation,
  payloadModalToggled,
  fieldMounted,
  fieldUnMounted,
  fieldSelected,
  fieldUnSelected,
  pageUnMounted,
  askedAIForDebuggingHelp,

  // Tech Debt WorkflowFnction actions
  setInitialWorkflowFunctions,
  updateWorkflowFunctions,
  updateWorkflowFunctionConnection,
  removeWorkflowFunction,
  addFunctionParameter,
  updateFunctionParameter,
  removeFunctionParameter,
  changeWorkflowFunctionActionType,
  setDefaultWorkflowFunctionActionType,
  setFunctionEndpoint,
  setFunctionEndpointOnly,
  setFunctionInputSectionFormData,
  setFunctionInputSectionSchema,
  setFunctionInputSectionFormDataItem,
  setFunctionInputSectionFormDataSubitem,
  setFunctionInputSectionFormDataSubSubitem,

  // Tech Debt to suppoer worflow creation
  setIdePageLoading,
} = sliceIde.actions;
export default sliceIde.reducer;
