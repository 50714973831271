import axios, { AxiosError } from 'axios';
import newrelic from '../newrelic';
import { cookies } from 'next/headers';
import config from '@/config';

const axiosClient = axios.create({
  // For most of our requests, we use '/api' as the base URL
  // however, there are some external requests that don't use this base URL
  // and we already got used to specify the full URL in the request
  baseURL: '',

  // `timeout` specifies the number of milliseconds before the request times out.
  // If the request takes longer than `timeout`, the request will be aborted.
  // timeout: 1000, // default is `0` (no timeout)

  headers: {
    'Content-Type': 'application/json',
  },

  xsrfCookieName: 'csrftoken',
});

axiosClient.interceptors.request.use(
  function (axiosConfig) {
    if (axiosConfig.withCredentials) {
      // Read the session jwt cookie value
      const sessionJwt = getCookieByName(config.SESSION_COOKIE_JWT);

      // And set it as a header
      axiosConfig.headers['Authorization'] = `Bearer ${sessionJwt}`;
    }

    return axiosConfig;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    // Global access to the response object of all axios requests
    return response;
  },
  (error: AxiosError) => {
    console.error(error);

    const data = error?.response?.data as { message: string };

    // Global access to the error object of all axios requests
    newrelic.noticeError(error, {
      message: data?.message,
      status: error?.response?.status,
      statusText: error?.response?.statusText,
      url: error?.config?.url,
    });

    return new Promise((_, reject) => reject(data));
  }
);

export default axiosClient;

export const getCookieByName = (name: string): string | undefined => {
  return document.cookie
    .split('; ')
    .find((x) => x.startsWith(name))
    ?.split('=')[1];
};
