import { ActionConfig, App, Connection } from '@strada/db';

export interface CustomHttpFormInput {
  operation: string;
  headersForm: any;
  queryForm: any;
  bodyForm: any;
}

export type FunctionParameter = {
  // Note: AK changed the id to be a string, because the id is a UUID, which is a string.
  // Historically, the id was a number, but that was a terrible design decision.
  id: number | string;
  paramName: string;
  paramType: string;
  defaultValue: string;
  isProvidedInExecute?: boolean;
  isReferenceInForm?: boolean;
};

export interface WorkflowFunction {
  id: string;
  app?: App;
  connection: Connection;
  functionParameters?: FunctionParameter[];
  inputSectionSchema?: Record<string, any>;
  inputSectionFormData?: Record<string, any>;
  actionType?: any | ActionConfig;
  endpoint: any;
  actionProperties: any | CustomHttpFormInput;
  title: string;
}

export interface WorkflowConfig {
  workflowFunctions: WorkflowFunction[];
  code: string;
  payload: Record<string, unknown>;
}

export function getAppFromWorkflowFunction(workflowFunction: WorkflowFunction): App {
  // @ts-ignore
  const app = workflowFunction?.app || workflowFunction?.connection?.app || workflowFunction?.actionType?.app;

  if (!app) {
    // This is a bug in Strada and usually correlates to custom HTTP
    // We need to fix this - but this is a temporary fix
    return {
      id: 'app_a77d1236-9eb1-4f8b-8243-8991fa626d99',
      createdAt: new Date(),
      updatedAt: new Date(),
      slug: 'custom-http',
      name: 'Custom HTTP',
      type: 'CUSTOM',
      deletedAt: null,
    };
  }

  return app;
}
