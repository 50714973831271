// @ts-strict-ignore
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { cn, isUrlValid } from '@/utils';
import inputStyles from '@/app/components/ui/input-field.styles.module.css';
import styles from './providers.module.css';

const authenticationTypes = [
  { value: 'none', label: 'No Auth' },
  { value: 'apikey', label: 'API Key', descriptiveLabel: 'Enter your API Key' },
  { value: 'bearer', label: 'Bearer', descriptiveLabel: 'Enter your Bearer Token' },
  { value: 'basic', label: 'Basic HTTP' },
  // { value: 'oauth2', label: 'OAuth2' }
];

const initialAuthenticationData = {
  token: '',
  username: '',
  password: '',
};

export default function CustomHttpConnection({
  onConnect,
  isLoading,
  defaultAlias,
  isFetchingAlias,
  buttonText,
  onClose,
}: {
  onConnect: (apiKey: string, alias: string, authentication: any) => void;
  isLoading: boolean;
  defaultAlias?: string;
  isFetchingAlias?: boolean;
  buttonText: string;
  onClose?: () => void;
}) {
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const [connectionAlias, setConnectionAlias] = useState('');
  const [authenticationData, setAuthenticationData] = useState<any>(initialAuthenticationData);
  const [authenticationType, setAuthenticationType] = useState('none');

  useEffect(() => {
    if (!url) {
      setError('');
      return;
    } else {
      if (!isUrlValid(url)) {
        setError('Invalid URL');
      } else {
        setError('');
      }
    }
  }, [url]);

  useEffect(() => setAuthenticationData(initialAuthenticationData), [authenticationType]);
  useEffect(() => {
    if (isFetchingAlias) return;
    if (defaultAlias) setConnectionAlias(defaultAlias);
  }, [isFetchingAlias, defaultAlias]);

  return (
    <div className={styles.container}>
      <div className={styles.inputFrame}>
        <div className={styles.frame}>
          <label htmlFor="slug" className="block font-detail font-medium leading-6 text-slate-900">
            Connection name
          </label>
          <Input
            onChange={(e) => setConnectionAlias(e.target.value)}
            value={isFetchingAlias ? ' ' : connectionAlias}
            disabled={isFetchingAlias}
            className={cn(inputStyles.inputField, 'font-detail')}
          />
        </div>

        <div className={styles.frame}>
          <label htmlFor="email" className="block font-detail font-medium leading-6 text-slate-900">
            Base URL
          </label>
          <Input
            type="url"
            name="endpoint-url"
            id="endpoint-url"
            autoComplete="off"
            required
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="https://example.com/api"
            className={cn(inputStyles.inputField, 'font-detail')}
          />
          <p
            className={classNames('text-red-700 font-detail', {
              hidden: !error,
            })}
          >
            {error}
          </p>
        </div>

        <div className={styles.frame}>
          <label htmlFor="email" className="block font-detail font-medium leading-6 text-slate-900">
            Authentication
          </label>
          <Select onValueChange={setAuthenticationType}>
            <SelectTrigger>
              <SelectValue placeholder="No Auth" />
            </SelectTrigger>
            <SelectContent>
              {authenticationTypes.map((type) => (
                <SelectItem key={type.label} value={type.value}>
                  {type.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {['apikey', 'bearer'].includes(authenticationType) && (
          <div className={styles.frame}>
            <label htmlFor="email" className="block font-detail font-medium leading-6 text-slate-900">
              {authenticationTypes.find((type) => type.value === authenticationType).descriptiveLabel}
            </label>
            <Input
              type="password"
              name="token"
              id="token"
              autoComplete="off"
              required
              value={authenticationData.token}
              onChange={(e) => setAuthenticationData({ token: e.target.value })}
              className={cn(inputStyles.inputField, 'font-detail')}
            />
          </div>
        )}

        {authenticationType === 'basic' && (
          <>
            <div className={styles.frame}>
              <label htmlFor="email" className="block font-detail font-medium leading-6 text-slate-900">
                Username
              </label>
              <Input
                type="text"
                name="username"
                id="username"
                autoComplete="off"
                required
                value={authenticationData.username}
                onChange={(e) => setAuthenticationData({ ...authenticationData, username: e.target.value })}
                className={cn(inputStyles.inputField, 'font-detail')}
              />
            </div>
            <div className={styles.frame}>
              <label htmlFor="email" className="block font-detail font-medium leading-6 text-slate-900">
                Password
              </label>
              <Input
                type="password"
                name="password"
                id="password"
                autoComplete="off"
                required
                value={authenticationData.password}
                onChange={(e) => setAuthenticationData({ ...authenticationData, password: e.target.value })}
                className={cn(inputStyles.inputField, 'font-detail')}
              />
            </div>
          </>
        )}
      </div>

      <div className={styles.buttonsFrame}>
        {onClose && (
          <Button
            onClick={onClose}
            variant="ghost"
            className={cn({
              'opacity-50 cursor-not-allowed': isLoading,
            })}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={() => onConnect(url, connectionAlias, { authenticationType, authenticationData })}
          disabled={isLoading}
          className={cn({
            'opacity-50 cursor-not-allowed': isLoading,
          })}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
}
